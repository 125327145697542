import { columns } from "../benchmarking-project/BenchmarkingTable.functions";
import isEmpty from "../../validation/is-empty";

export function getRoleBasedColumns(user) {
  let roleBasedColumns = [];
  columns().forEach((column) => {
    
    if(isEmpty(column.visible) || column.visible){
      if (!isEmpty(column.roles)) {
        let isRoleExists = user.roles.some((rol) => column.roles.includes(rol));
        if (isRoleExists) roleBasedColumns.push(column);
      } else {
        roleBasedColumns.push(column);
      }
    }
  });
  return roleBasedColumns;
}

export function getDecoratedData(data, sensitivityLevelCodes) {
  const benchmarksArray = data.map((element) => {
    element.informationSensitivityLevelDesc = isEmpty(
      sensitivityLevelCodes.data[element.informationSensitivityLevel]
    )
      ? element.informationSensitivityLevel
      : sensitivityLevelCodes.data[element.informationSensitivityLevel].label;
    return element;
  });
  return benchmarksArray;
}
