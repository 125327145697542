import { authHeader } from "../../_helpers/auth-header";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";

export async function onSubmit(file, projectID, project, setError, setModal) {
  setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });

  const payload = {
    project_id: projectID,
  };
  const formData = new FormData();

  try {
    formData.append("projectImage", file, file.name);
  } catch (e) {
    console.log(e.message);
    return setError({
      text: "Error uploading file",
      type: "feedback-error",
    });
  }
  formData.append("field", JSON.stringify(payload));

  const url = projectAPIs().upload_project_image + "?projectId=" + projectID;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    projectUpdate(project);
    setError({
      text: tranStr("Saved Successfully"),
      type: "feedback-success",
    });

    AutoCloseModal(setModal);
  } else {
    try {
      response = await response.json();
      return setError({
        text: response.message,
        type: "feedback-error",
      });
    } catch (e) {
      return setError({
        text: "Connection Error",
        type: "feedback-error",
      });
    }
  }
}
