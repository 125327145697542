import React, { useState } from "react";

import { createCbs, deleteCbs } from "../../api/cbs/CbsAPI";
import Modal from "../modal/Modal";
import { AutoCloseModal } from "../../components/modal/Modal";

import { CbsName } from "./CbsAdmin.components";

export function CbsAdminModal(props) {
  const { cbsCodes } = props;
  const { setSelectedCbs } = props;
  const { cbs } = props;
  const { isDeleteEnabled } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <div
        id="cbs-admin-modal-button"
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        Add New CBS
      </div>
      <Modal
        // Modal Props
        title={"Create New CBS"}
        Component={EditCbsAdminModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        cbsCodes={cbsCodes}
        setSelectedCbs={setSelectedCbs}
        cbs={cbs}
        isDeleteEnabled={isDeleteEnabled}
      />
    </div>
  );
}

function EditCbsAdminModal(props) {
  const { cbsCodes } = props;
  const { setSelectedCbs } = props;
  const { cbs } = props;
  const { setModal } = props;
  const { isDeleteEnabled } = props;

  const [cbsName, setCbsName] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="cbs-modal">
      <div className="general-row-container">
        You can create a new blank CBS by entering the CBS name (typically the
        acronym) that will be used to identify the CBS below. As the name is
        used as the identifier, it cannot be the same as an existing CBS.
      </div>

      <div className="general-row-container">
        <div className="cbs-input-field">CBS Name</div>
      </div>
      <div className="general-row-container">
        <CbsName get={cbsName} set={setCbsName} />
      </div>
      <div className="general-row-container">{message}</div>

      <div className="general-row-container">
        <CreateCbsButton
          cbsName={cbsName}
          cbs={cbs}
          cbsCodes={cbsCodes}
          setSelectedCbs={setSelectedCbs}
          setMessage={setMessage}
          setModal={setModal}
        />
      </div>
      <div className="general-button-container">
        <DeleteCbsButton
          cbsCodes={cbsCodes}
          setSelectedCbs={setSelectedCbs}
          cbs={cbs}
          setMessage={setMessage}
          setModal={setModal}
          isDeleteEnabled={isDeleteEnabled}
        />
      </div>
    </div>
  );
}

function CreateCbsButton(props) {
  const { cbs } = props;
  const { cbsName } = props;
  const { setSelectedCbs } = props;
  const { cbsCodes } = props;
  const { setMessage } = props;
  const { setModal } = props;

  return (
    <div
      id="cbs-create-cbs-button"
      className="general-upload-button"
      onClick={async () => {
        setMessage("Creating...");

        let result = await createCbs(cbsName);

        if (result.success) {
          setMessage(result.message);
          await cbs.set(await cbs.get());
          await cbsCodes.set(await cbsCodes.get(true));
          setSelectedCbs(cbsName);
          AutoCloseModal(setModal);
        } else {
          setMessage(result.message);
        }
      }}
    >
      Create
    </div>
  );
}

function DeleteCbsButton(props) {
  const { cbs } = props;
  const { cbsCodes } = props;
  const { setSelectedCbs } = props;
  const { setMessage } = props;
  const { setModal } = props;
  const { isDeleteEnabled } = props;

  if (!isDeleteEnabled) {
    return null;
  }

  return (
    <div
      id={"cbs-delete-cbs-button"}
      className="general-modal-button"
      onClick={async () => {
        setMessage("Deleting...");

        const payload = [cbs?.data?.id];
        let result = await deleteCbs(payload);

        if (result.success) {
          setMessage(result.message);
          AutoCloseModal(setModal);
          await cbsCodes.set(await cbsCodes.get(true));
          setSelectedCbs("");
        } else {
          setMessage(result.message);
        }
      }}
    >
      Delete
    </div>
  );
}
