import isEmpty from "./is-empty";

export function displayProjectImage(project) {
  const file = "default_image.png";
  const path = pathPicker();
  const container = "project-id-" + project.id;
  const key = project.accessKey;
  let image = path + "/" + container + "/" + file + "?" + key;
  if (isEmpty(image)) {
    return "";
  }

  return image.includes("imgur") ||
    image.includes("default_avatar.png") ||
    !image.includes("project-id-")
    ? ""
    : image;
}

export function displayUserImage(user) {
  const file = "default_image.png";
  const path = pathPicker();
  const container = "user-id-" + user.id;
  const key = user.accessKey;
  let image = path + "/" + container + "/" + file + "?" + key;
  if (isEmpty(image)) {
    return "";
  }

  return image.includes("imgur") ||
    image.includes("default_avatar.png") ||
    !image.includes("user-id-")
    ? ""
    : image;
}

function pathPicker() {
  // Domain
  const host = window.location.hostname;
  if (host.includes("localhost")) {
    // Local
    return "https://stcostclaritydev001.blob.core.windows.net";
  }
  if (host.includes("dev")) {
    // Dev Deployment
    return "https://stcostclaritydev001.blob.core.windows.net";
  }
  if (host.includes("test")) {
    // Test Deployment
    return "https://stcostclaritytst001.blob.core.windows.net";
  }
  if (host.includes("www")) {
    // Production Deployment
    return "https://stcostclarityprd001.blob.core.windows.net";
  }
}
