import React, { useState } from "react";

import Modal from "../modal/Modal";

import { hasRoles } from "../../utils/roles";
import { tranStr } from "../../utils/translation";

import "../estimates/EstimatesEdit.css";
import { CostAllocation } from "../cost-planning-area-schedules/CostAllocation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import isEmpty from "../../validation/is-empty";

export default function BudgetAllocation(props) {
  const { project } = props;
  const { estimate } = props;
  const { user } = props;
  const { CP, CPs } = props;

  const [modal, setModal] = useState(false);
  const data = {
    estimate_id: estimate.id,
    cost_plan_id: CP.cost_plan_id,
    projectID: project.id,
    project: project,
    CPs: CPs,
    setError: () => null, // gets overwritten inside CostAllocation to the correct function
    url: cpAPIs().allocate_cost,
  };

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }
  if (isEmpty(CP.budgets)) {
    return null;
  }
  return (
    <div className="estimate-edit-wrapper">
      <Modal
        // Modal Props
        Component={CostAllocation}
        title={tranStr("Allocate Cost")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        estimate={estimate}
        data={data}
        allocationItems={CP.budgets}
        allocationType={"Budgets"}
      />
      <div
        id={"button-budget-allocation"}
        className="estimate-edit-button"
        onClick={() => {
          setModal(true);
        }}
        title="Budget Allocation"
      >
        <i className="fas fa-coins"></i>
      </div>
    </div>
  );
}
