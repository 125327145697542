import React, { useState, useEffect } from "react";
import {
  onSubmit,
  calculatePartNumber,
  calculateVersionNumber,
} from "./CostPlanningUpload.functions";
import { tranStr, translate } from "../../utils/translation";
import { randomNumberGenerator } from "../../helpers/common.functions";
import "../dropzone/Dropzone.css";
import cloud from "../../img/baseline-cloud_upload-24px.svg";

import "./CostPlanningUpload.css";
import isEmpty from "../../validation/is-empty";
import { useCbs, useCbsCodes } from "../../api/cbs/CbsAPI";

export default function CostPlanningUpload(props) {
  const { projectID } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;

  const { setShow } = props;

  // List of CBS
  const isNotPublished = false;
  const { cbsCodes } = useCbsCodes(isNotPublished);
  // Selected CBS
  const [selectedCbs, setSelectedCbs] = useState("");
  const { cbs } = useCbs(selectedCbs);

  const [file, setFile] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [messages, setMessages] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [date, setDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );
  const [stage, setStage] = useState(1);
  const [revision, setRevision] = useState(1);
  const [part, setPart] = useState(0);
  const [error, setError] = useState({});

  // To reset File Input
  const [inputKey, setInputKey] = useState(0);

  // Establish Stage Number
  useEffect(() => {
    // If a selectedStage is available use it
    if (!isEmpty(CP)) {
      setStage(CP.stage);
    } else if (!isEmpty(CPs.data)) {
      // Otherwise use the first available stage
      setStage(CPs.data[0].stage);
    }
  }, [CPs.data, CP]);

  // Establish Starting Part Number
  useEffect(() => {
    if (!isEmpty(CPs.data)) {
      setPart(calculatePartNumber(CPs, stage, revision));
    }
  }, [CPs, stage, revision]);

  // Establish Starting Version Number
  useEffect(() => {
    if (!isEmpty(CPs.data)) {
      setRevision(calculateVersionNumber(CPs, stage));
    }
  }, [CPs, stage]);

  useEffect(() => {
    if (!isEmpty(cbsCodes?.data)) {
      setSelectedCbs(cbsCodes.data[0]);
    }
  }, [cbsCodes.data]);

  // Find the CBS Codes
  const categoryGroup = cbs?.data?.categoryGroups?.find(
    (g) => g.categoryName === "Key Categories"
  );

  const designContingencyItem = categoryGroup?.categoryItems?.find(
    (g) => g.description === "Design Contingency"
  );
  const taxItem = categoryGroup?.categoryItems?.find(
    (g) => g.description === "Tax"
  );

  let designContingencyCode = [];
  let taxCode = [];

  if (!isEmpty(designContingencyItem)) {
    designContingencyCode = designContingencyItem.codes;
  }

  if (!isEmpty(taxItem)) {
    taxCode = taxItem.codes;
  }

  let data = {
    projectID: projectID,
    project: project,
    stage: stage,
    revision: revision,
    date: date,
    part: part,
    standard: selectedCbs,
    file: file,
    CPs: CPs,
    setShow: setShow,
    designContingencyCode: designContingencyCode,
    taxCode: taxCode,
    setError: setError,
    setFile: setFile,
    setMessages: setMessages,
    setFilePreview: setFilePreview,
  };

  // Add a random query value to prevent caching
  const randomQueryValue = "?" + Date.now();

  return (
    <div className="cost-planning-upload-container">
      <div className="cost-planning-upload">
        <div className="cost-planning-upload-control-container">
          <Upload
            inputKey={inputKey}
            setInputKey={setInputKey}
            setFile={setFile}
            setFilePreview={setFilePreview}
            setHighlight={setHighlight}
            highlight={highlight}
            setError={setError}
            setMessages={setMessages}
          />
          <div className="cost-planning-upload-control">
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Cost Planning Guide")}:
              </div>
              <a
                className="cost-planning-upload-document-button"
                href={
                  "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost Planning Guide.pdf" +
                  randomQueryValue
                }
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {translate("Download")}
              </a>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Codes, Sectors List (NRM)")}:
              </div>
              <a
                className="cost-planning-upload-document-button"
                href={
                  "https://stcostclarityprd001.blob.core.windows.net/1-guides/Codes and Sector Lists (NRM).pdf" +
                  randomQueryValue
                }
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {translate("Download")}
              </a>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Codes, Sectors List (ICMS)")}:
              </div>
              <a
                className="cost-planning-upload-document-button"
                href={
                  "https://stcostclarityprd001.blob.core.windows.net/1-guides/Codes and Sector Lists (ICMS).pdf" +
                  randomQueryValue
                }
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {translate("Download")}
              </a>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Cost Planning Template")}:
              </div>
              <a
                className="cost-planning-upload-document-button"
                href={
                  "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost Estimate Template.xlsx" +
                  randomQueryValue
                }
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {translate("Download")}
              </a>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Date (YYYY-MM-DD)")}:
              </div>
              <input
                className="cost-planning-upload-control-input"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Upload to Stage")}:
              </div>
              <select
                value={stage}
                className="cost-planning-upload-control-input"
                onChange={(e) => {
                  setStage(e.target.value);
                }}
              >
                {selectStages(CPs.data)}
              </select>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Upload to Release")}:
              </div>
              <select
                value={revision}
                className="cost-planning-upload-control-input"
                onChange={(e) => {
                  setRevision(e.target.value);
                }}
              >
                {selectVersions(CPs, stage)}
              </select>
            </div>
            <div className="cost-planning-upload-control-row">
              <div className="cost-planning-upload-control-label">
                {translate("Cost Breakdown Structure")}:
              </div>
              <select
                value={selectedCbs}
                className="cost-planning-upload-control-input"
                onChange={(e) => {
                  setSelectedCbs(e.target.value);
                }}
              >
                {selectOptions(
                  cbsCodes?.data?.map((c) => {
                    return { value: c, label: c };
                  })
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="tpi-upload-button-container">
          <FileTray files={filePreview} messages={messages} part={part} />
        </div>
        <div className="general-row-container">
          <Error error={error} setError={setError} />
        </div>
        <div className="general-button-container">
          <div
            className="general-upload-button"
            onClick={() => {
              onSubmit(data);
            }}
          >
            {translate("Upload")}
          </div>
        </div>
      </div>
      <div className="cost-planning-upload-cbs-control-container">
        <div className="cost-planning-upload-cbs-control">
          <div className="general-row-container">
            <div className="cost-planning-upload-cbs-note">
              {tranStr(
                "The below codes have been detected in the selected CBS for identifying key values in your estimate. If these key values relate to your estimate, please ensure these codes have been used in your elemental coding exactly as shown below, otherwise features in Cost Clarity that depend on them will not work"
              )}
              :
            </div>
          </div>
          <DisplayRequiredCodes selectedCostType="On Costs" cbs={cbs} />
          <DisplayRequiredCodes selectedCostType="Key Categories" cbs={cbs} />
        </div>
      </div>
    </div>
  );
}

function DisplayRequiredCodes(props) {
  const { selectedCostType } = props;
  const { cbs } = props;

  const categoryGroup = cbs?.data?.categoryGroups?.find(
    (g) => g.categoryName === selectedCostType
  );

  const display = [];

  categoryGroup?.categoryItems?.forEach((categoryItem) => {
    display.push(
      <div
        key={categoryItem.id}
        className="cost-planning-upload-cbs-control-row"
      >
        <div className="cost-planning-upload-cbs-control-label">
          {translate(categoryItem.description)}:
        </div>
        <div className="cost-planning-upload-cbs-control-label">
          {displayCodes(categoryItem.codes)}
        </div>
      </div>
    );
  });

  return display;
}

function displayCodes(codes) {
  if (isEmpty(codes)) {
    return null;
  }

  const display = [];
  codes?.forEach((code, i) => {
    if (i < codes.length - 1) {
      display.push(code + ", ");
    } else {
      display.push(code);
    }
  });

  return display;
}

function Error(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function Upload(props) {
  const { setFile, setFilePreview } = props;
  const { highlight, setHighlight } = props;
  const { setError, setMessages } = props;
  const { inputKey, setInputKey } = props; // Change to reset the File Input

  let classType = "cost-planning-upload-button";
  if (highlight) {
    classType = "cost-planning-upload-button-highlight";
  }

  return (
    <label
      className={classType}
      onDrop={(e) => {
        e.preventDefault();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          let newFiles = e.dataTransfer.files;
          setMessages([]);
          setError({});
          setFile((files) => {
            return handleFiles(files, newFiles);
          });
          setFilePreview((files) => {
            return handleFiles(files, newFiles);
          });
        }
        setHighlight(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setHighlight(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setHighlight(false);
      }}
    >
      <input
        key={inputKey}
        type="file"
        multiple
        className="tpi-upload-button"
        onChange={(e) => {
          e.preventDefault();
          setInputKey(randomNumberGenerator(0, 1).toString(36));
          if (e.target.files && e.target.files.length > 0) {
            let newFiles = e.target.files;
            setMessages([]);
            setError({});
            setFile((files) => {
              return handleFiles(files, newFiles);
            });
            setFilePreview((files) => {
              return handleFiles(files, newFiles);
            });
          }
        }}
      />
      <img alt="upload" className="Icon" src={cloud} />
    </label>
  );
}

function handleFiles(files, newFiles) {
  let oldFiles = [...files];
  for (let file of newFiles) {
    oldFiles.push(file);
  }
  return oldFiles;
}

function FileTray(props) {
  const { files } = props;
  const { messages } = props;
  const { part } = props;

  let display = [];

  for (let i = 0; i < files.length; i++) {
    display.push(
      <div className="cost-planning-files-item" key={i}>
        <b>
          <div className="cost-planning-files-part">
            {tranStr("Part") + ` ${part + (i + 1)}`}
          </div>
        </b>
        <div className="cost-planning-files-item-name">{files[i].name}</div>
        <div className="cost-planning-message">
          <Messages messages={messages} i={i} />
        </div>
      </div>
    );
  }

  return <div className="cost-planning-upload-files">{display}</div>;
}

function Messages(props) {
  const { messages, i } = props;

  if (!isEmpty(messages)) {
    if (!isEmpty(messages[i])) {
      if (messages[i].status === "fulfilled") {
        return (
          <div className="feedback-success">{messages[i].value.message}</div>
        );
      }

      if (messages[i].status === "rejected") {
        return (
          <div className="feedback-error">{messages[i].reason.message}</div>
        );
      }
    }
  } else {
    return "";
  }
}

function selectStages(array) {
  return array.map(function (item, i) {
    if (item.stage_name !== "") {
      return (
        <option key={i} value={item.stage}>
          {item.stage_name}
        </option>
      );
    } else {
      return null;
    }
  });
}

function selectVersions(CPs, stage) {
  // Unique list of revisions
  const revisions = new Set();

  // Within this stage
  CPs.data.forEach((CPStage) => {
    CPStage.versions.forEach((CP) => {
      if (parseInt(CP.stage) === parseInt(stage)) {
        // Extract all the version numbers
        CP.estimates.forEach((estimate) => {
          revisions.add(estimate.revision);
        });
      }
    });
  });

  // Format the revisions into the drop down list
  const revisionDropDownList = [];

  revisions.forEach((r) => {
    revisionDropDownList.push({
      revision: r,
      revision_name: tranStr("Release") + " " + r,
    });
  });

  // Add latest
  if (!isEmpty(revisionDropDownList)) {
    const latestRevision = revisionDropDownList[0];
    revisionDropDownList.unshift({
      revision: latestRevision.revision + 1,
      revision_name:
        tranStr("Release") +
        " " +
        (latestRevision.revision + 1) +
        " " +
        tranStr("(New)"),
    });
  }

  // Add the default starting revision
  if (isEmpty(revisionDropDownList)) {
    revisionDropDownList.push({
      revision: 1,
      revision_name: tranStr("Release") + " " + 1 + " " + tranStr("(New)"),
    });
  }

  return revisionDropDownList.map(function (item, i) {
    if (item.revision_name !== "") {
      return (
        <option key={i} value={item.revision}>
          {item.revision_name}
        </option>
      );
    } else {
      return null;
    }
  });
}

function selectOptions(array) {
  return array?.map((item, i) => {
    return (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    );
  });
}
