// React
import React, { useState } from "react";
import ProfileModal from "../profile/ProfileModal";

// Functions
import isEmpty from "../../validation/is-empty";
import { checkLength } from "../profile-card/profile.functions";
import { translate, tranStr } from "../../utils/translation";
import { displayUserImage } from "../../validation/image-clean";

// Styles
import defaultAvatar from "../../img/default_avatar.png";
import "./PDCPProjectTeam.css";
import { useHistory } from "react-router-dom";

import { ImageDisplay } from "../images/ImageDisplay";

export default function PDCPProjectTeam(props) {
  const { project } = props;
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  return (
    <>
      <div className="project-team">
        <Profile
          user={project.project_leader_user}
          role={translate("Project Leader")}
          projectName={project.title}
          setSelectedUser={setSelectedUser}
          setShow={setShow}
        />
        <Profile
          user={project.peer_reviewer_user}
          role={translate("Cost Manager")}
          projectName={project.title}
          setSelectedUser={setSelectedUser}
          setShow={setShow}
        />
        <ProfileModal
          user={selectedUser}
          show={show}
          setShow={setShow}
          projectName={project.title}
        />
      </div>
      <ViewAllProjectTeamMembers projectId={project.id} />
    </>
  );
}

function Profile(props) {
  const { user, setSelectedUser, setShow, role } = props;
  if (isEmpty(user)) {
    return (
      <div className="project-profile">
        <img className="project-profile-image" src={defaultAvatar} alt="" />
        <div className="project-profile-details">
          <div className="project-profile-name">{tranStr("Not Set")}</div>
          <div className="project-profile-role">{role}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="project-profile">
      <div
        onClick={() => {
          setSelectedUser(user);
          setShow(true);
        }}
      >
        <ImageDisplay
          image={displayUserImage(user)}
          imageWidth={"80px"}
          imageHeight={"80px"}
          borderRadius={"40px"}
        />
      </div>

      <div className="dashboard-profile-details">
        <div className="project-profile-name">
          {checkLength(user.name + " " + user.surname, 24)}
        </div>
        <div className="project-profile-role">{props.role}</div>
      </div>
    </div>
  );
}

function ViewAllProjectTeamMembers(props) {
  let history = useHistory();
  const { projectId } = props;
  return (
    <div
      className="project-team-members-link"
      onClick={() => history.push("/project/" + projectId + "/project-team")}
    >
      {tranStr("View All Project Team Members")}
    </div>
  );
}
