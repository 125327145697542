import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import Modal from "../modal/Modal";

// Functions
import {
  importLocalisation,
  checkNamespace,
} from "./AdminLocalisation.functions";

//Components
import DuplicateNamespaceModal from "./DuplicateNamespaceModal";

export default function AdminLocalisationUpload(props) {
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [replace, setReplace] = useState(false);
  const [error, setError] = useState({});
  const { terminologySetName, languageCode, setTerminologySetName } = props;
  const { namespacesObject, setSelectedNamespace } = props;
  const data = {
    file: file,
    setFile: setFile,
    setError: setError,
    terminologySetName: terminologySetName,
    languageCode: languageCode,
    namespacesObject: namespacesObject,
    setSelectedNamespace: setSelectedNamespace,
    setTerminologySetName: setTerminologySetName,
    setModal: setModal,
    setReplace: setReplace,
    replace: replace,
  };
  return (
    <div>
      <Modal
        // Modal Props
        title={"Namespace Exists"}
        Component={DuplicateNamespaceModal}
        modal={modal}
        setModal={setModal}
        // Component Props

        label={"Namespace Exists"}
        show={modal}
        setShow={setModal}
        setReplace={setReplace}
        data={data}
      />
      <div className="alc-column">
        <div className="alc-row">
          <b></b>
        </div>

        <div className="alc-row">
          It is recommended to import either a new list created from the master,
          or to export an existing list and update it.
        </div>
      </div>

      <div className="general-row-container">
        <Error error={error} setError={setError} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} setError={setError} />
      </div>
      <div className="general-row-container">
        <FileTray file={file} />
      </div>

      <div className="general-button-container">
        <div
          className="general-upload-button"
          onClick={() => {
            onSubmit(data);
          }}
        >
          Import Set
        </div>
      </div>
    </div>
  );
}
async function onSubmit(data) {
  if (data.terminologySetName === "" || data.terminologySetName === null) {
    return data.setError({
      text: "Please enter terminology set name",
      type: "feedback-error",
    });
  }
  if (data.file === null) {
    return data.setError({
      text: "Please attach a file",
      type: "feedback-error",
    });
  }
  if (data.file.name.slice(-4) !== "xlsx") {
    return data.setError({
      text: "Please check the file type. Only XLSX files are allowed.",
      type: "feedback-error",
    });
  }
  data.setError({
    text: "Creating...",
    type: "feedback-success",
  });
  let name = data.languageCode + "-" + data.terminologySetName;
  const exists = await checkNamespace(name);
  //if same namespace exists
  if (exists) {
    data.setError({
      text: "",
      type: "feedback-success",
    });
    await data.setReplace(false);
    await data.setModal(true);
    if (!(await data.replace)) {
      return null;
    }
  } else {
    let response = await importLocalisation(name, data.file);

    if (response === "Success") {
      data.namespacesObject.set(await data.namespacesObject.get());
      await data.setSelectedNamespace(name.toLowerCase());
      data.setTerminologySetName("");
      data.setError({
        text: "Created Successfully",
        type: "feedback-success",
      });
      data.setFile(null);
    } else {
      data.setError({
        text: response,
        type: "feedback-error",
      });
    }
  }
}
function UploadFile(props) {
  const { setFile, setError } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          setError({
            text: "",
            type: "feedback-success",
          });
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      Attach File
    </label>
  );
}
function FileTray(props) {
  const { file } = props;
  let fileName = "No File Added";

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pcu-file-display">
      <b>File:</b> {fileName}
    </div>
  );
}
function Error(props) {
  const { error } = props;
  if (isEmpty(error)) {
    return null;
  }
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
