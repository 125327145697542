import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { extractYearFromISODateString } from "../../helpers/common.functions";

import BenchmarkingAnonymity from "./BenchmarkingAnonymity";
import BenchmarkingDisplayTable from "./BenchmarkingDisplayTable";
import BenchmarkingMetrics from "./BenchmarkingMetrics";
import AverageCostCategories from "./AverageCostCategories";
import SectionTitleControl from "./SectionTitleControl";
import BenchmarkingHeatMap from "./BenchmarkingHeatMap";
import BenchmarkingCostplanComparisons from "./BenchmarkingCostplanComparisons";
import ProjectComparison from "./ProjectComparison";

import {
  escalation,
  toggleEscalation,
} from "../../helpers/escalation.functions";

import "./BenchmarkSection.css";
import { translate, tranStr } from "../../utils/translation";

import CostBenchmarking from "../charts/CostBenchmarking";
import ContractorOnCostComparisonChart from "../charts/ContractorOnCostComparisonChart";
import { useCbs } from "../../api/cbs/CbsAPI";
import { BenchmarkingJustification } from "./BenchmarkingJustification";
import BenchmarkProjectsMap from "./BenchmarkProjectsMap";

/// props validation | SQ(javascript:S6774)
BenchmarkSection.propTypes = {
  caller: PropTypes.string,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  is_locality_factor_active: PropTypes.bool,
  locality_factor: PropTypes.number,
  title: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  baseDate: PropTypes.string,
  TPI: PropTypes.object,
  LFI: PropTypes.array,
  onBenchmarkAnonymityToggle: PropTypes.func,
  settings: PropTypes.array,
  onSettingsToggle: PropTypes.func,
  currencyExchange: PropTypes.array,
  currency: PropTypes.string,
  project: PropTypes.object,
  isLocalRegionFactorEnabled: PropTypes.bool,
  isGlobalBenchmarking: PropTypes.bool,
};
///
export default function BenchmarkSection(props) {
  const { caller } = props;

  // for certain callers, render charts without highlighting the first entity
  const skipHighlight = ["MASTER_BENCHMARKING"].includes(caller);

  const { thisBenchmark } = props;
  const { benchmarks } = props;
  const { is_locality_factor_active } = props; // Cost Plan Locality Factor Override Toggle
  const { locality_factor } = props; // Cost Plan Locality Factor Override Value

  const { title, unitOfMeasure, currencySymbol, city, country } = props;
  const { baseDate } = props;
  const { TPI } = props;
  const { LFI } = props;
  const { onBenchmarkAnonymityToggle } = props;
  const { settings } = props;
  const { onSettingsToggle } = props;
  const { currencyExchange, isLocalRegionFactorEnabled } = props;
  const { currency } = props;
  const { cbs } = useCbs(thisBenchmark?.standard);
  const { project } = props;
  const { isGlobalBenchmarking } = props;

  const [futureYear, setFutureYear] = useState(0);
  const [isEscalated, setIsEscalated] = useState(true);
  const [isElementalUnitRate, setIsElementalUnitRate] = useState(false);

  const DISPLAY_UNIT = "m²";

  // Set the future year to the base date of the estimate or benchmark
  useEffect(() => {
    if (!isEmpty(thisBenchmark)) {
      // if baseDate is available in props, prefer it over thisBenchmark.base_date
      const base_date = baseDate || thisBenchmark.base_date;
      // pick year from date
      const baseYear = extractYearFromISODateString(base_date);
      // set year for use with TPI / Escalation
      setFutureYear(baseYear);
    }
  }, [thisBenchmark, baseDate]);

  // Return null if empty data
  if (isEmpty(benchmarks) || isEmpty(thisBenchmark)) {
    return (
      <div className="benchmark-section-empty">
        {translate("No benchmarks selected")}
      </div>
    );
  }

  let benchmarkOrProject = thisBenchmark;

  if (caller === "COST_PLANNING") {
    benchmarkOrProject = {
      lat: project.lat,
      lng: project.lng,
      project_name: project.title,
      id: project.id,
    };
  }

  let displayUnit =
    isLocalRegionFactorEnabled && caller !== "COST_PLANNING"
      ? unitOfMeasure
      : DISPLAY_UNIT;

  // Escalation
  const escalationData = {
    costData: {
      benchmarks: [thisBenchmark, ...benchmarks],
    },
    inflationData: {
      TPI: TPI,
      futureYear: futureYear,
    },
    localityData: {
      LFI: LFI,
      projectCity: city,
      is_locality_factor_active: is_locality_factor_active,
      locality_factor: locality_factor,
      isGlobalBenchmarking: isGlobalBenchmarking,
    },
    currencyData: {
      currencyExchange: currencyExchange,
      currency: isGlobalBenchmarking ? currency : thisBenchmark.currency,
    },
    isLocalRegionFactorEnabled : isLocalRegionFactorEnabled
  };

  escalation(escalationData);

  // Toggle escalation on and off
  toggleEscalation(isEscalated, [thisBenchmark, ...benchmarks]);

  return (
    <div className="benchmarking-estimate-section">
      <div className="estimate-benchmarking-dashboard">
        <div className="estimate-benchmarking-dashboard-horizontal-charts">
          <ProjectComparison
            caller={caller}
            skipHighlight={skipHighlight}
            unitOfMeasure={displayUnit}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            isEscalated={isEscalated}
            futureYear={futureYear}
            isLocalRegionFactorEnabled={
              caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
            }
          />
          <div className="estimate-benchmarking-dashboard-vertical-charts">
            <AverageCostCategories
              caller={caller}
              title={title}
              thisBenchmark={thisBenchmark}
              benchmarks={benchmarks}
              isLocalRegionFactorEnabled={
                caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
              }
            />
          </div>
        </div>
        <div className="estimate-benchmarking-dashboard-horizontal-charts">
          <CostBenchmarking
            caller={caller}
            unitOfMeasure={displayUnit}
            title={title}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            isLocalRegionFactorEnabled={
              caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
            }
          />
          <div className="estimate-benchmarking-dashboard-vertical-charts">
            <ContractorOnCostComparisonChart
              title={title}
              thisBenchmark={thisBenchmark}
              benchmarks={benchmarks}
              cbs={cbs}
            />
          </div>
        </div>

        <div className="benchmarking-table-row">
          <BenchmarkingDisplayTable
            skipHighlight={skipHighlight}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            isEscalated={isEscalated}
            setIsEscalated={setIsEscalated}
            unitOfMeasure={caller === "COST_PLANNING" ? displayUnit : ""}
            country={country}
            currencySymbol={currencySymbol}
            cbs={cbs}
            isLocalRegionFactorEnabled={
              caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
            }
            caller={caller}
            isGlobalBenchmarking={isGlobalBenchmarking}
          />
          <BenchmarkingJustification
            benchmarks={[thisBenchmark, ...benchmarks]}
            isLocalRegionFactorEnabled={
              caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
            }
          />
          <BenchmarkingAnonymity
            caller={caller}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
          />
        </div>
      </div>
      <SectionTitleControl
        title={translate("Dataset Comparison")}
        isElementalUnitRate={isElementalUnitRate}
        setIsElementalUnitRate={setIsElementalUnitRate}
      />
      <BenchmarkingHeatMap
        skipHighlight={skipHighlight}
        title={title}
        unitOfMeasure={displayUnit}
        currencySymbol={currencySymbol}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="Construction Cost"
        costTypeLabel={tranStr("Construction")}
        cbs={cbs}
        isLocalRegionFactorEnabled={
          caller === "COST_PLANNING" ? true : isLocalRegionFactorEnabled
        }
        caller={caller}
      />
      <BenchmarkingHeatMap
        skipHighlight={skipHighlight}
        title={title}
        unitOfMeasure={displayUnit}
        currencySymbol={currencySymbol}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="Project Cost"
        costTypeLabel={tranStr("Project")}
        cbs={cbs}
      />
      <BenchmarkingCostplanComparisons
        skipHighlight={skipHighlight}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="On Costs"
        costType="Project"
        costTypeLabel={tranStr("Project")}
        cbs={cbs}
      />
      <BenchmarkingMetrics
        skipHighlight={skipHighlight}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks}
        currencySymbol={currencySymbol}
        settings={settings}
        onSettingsToggle={onSettingsToggle}
      />
      <BenchmarkProjectsMap
        thisBenchmark={benchmarkOrProject}
        benchmarks={benchmarks}
        caller={caller}
      />
    </div>
  );
}
