import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { translate, tranStr } from "../../utils/translation";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";
import PropTypes from "prop-types";

PostContractEditRisks.propTypes = {
  projectID: PropTypes.string,
  postContract: PropTypes.object,
  postContractId: PropTypes.string,
  risks: PropTypes.array,
};

export default function PostContractEditRisks(props) {
  const { projectID } = props;
  const { risks } = props;
  const { postContract } = props;
  const { postContractId } = props;

  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      risk_opportunity: "",
      urgency: "",
    },
  ]);
  useEffect(() => {
    if (!isEmpty(risks)) {
      setFields(
        risks.map((r) => {
          return {
            risk_opportunity: r.risk_opportunity,
            urgency: r.urgency,
          };
        })
      );
    }
  }, [risks]);

  if (isEmpty(postContract)) {
    return (
      <div>
        {translate(
          "Risks and Opportunities are available after a Post Contract has been uploaded."
        )}
      </div>
    );
  }

  return (
    <div className="project-control-advice">
      <div className="advice-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          Add up to 5 Risks / Opportunities below to be displayed on the Post
          Contract Dashboard.
        </div>
      </div>
      <FieldHeadings />
      {DecisionFields()}
      <div className="general-row-container">
        <AddField />
      </div>
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton />
      </div>
    </div>
  );

  function AddField() {
    return (
      <div
        className="general-modal-button"
        onClick={() => {
          let temp = [...fields];

          if (temp.length >= 5) {
            return;
          }
          temp.push({
            risk_opportunity: "",
            urgency: "",
          });
          setFields(temp);
        }}
      >
        Add Field
      </div>
    );
  }

  function SaveButton() {
    return (
      <div className="general-upload-button" onClick={Save}>
        Save
      </div>
    );
  }

  function FieldHeadings() {
    return (
      <div className="advice-field-container">
        <div className="advice-decision">
          <b>Risk</b>
        </div>

        <div className="advice-required-by">
          <b>Severity</b>
        </div>

        <div className="advice-delete-spacer"></div>
      </div>
    );
  }

  function DecisionFields() {
    return fields.map((f, i) => {
      return (
        <div key={i} className="advice-field-container">
          <input
            className="advice-decision"
            value={f.risk_opportunity}
            maxLength={90}
            onChange={(e) => {
              updateField("risk_opportunity", e.target.value, i, fields);
            }}
          />

          <select
            className="advice-required-by"
            value={f.urgency}
            maxLength={10}
            onChange={(e) => {
              updateField("urgency", e.target.value, i, fields);
            }}
          >
            {selectOptions(["", "Low", "Medium", "High"])}
          </select>

          <div
            className="advice-delete-button"
            onClick={() => deleteField(i, fields)}
          >
            <i className="far fa-trash-alt" />
          </div>
        </div>
      );
    });
  }

  function selectOptions(array) {
    return array.map(function (item, i) {
      return (
        <option key={i} value={item}>
          {tranStr(item)}
        </option>
      );
    });
  }

  function updateField(field, value, i, array) {
    let temp = [...array];
    temp[i][field] = value;
    setFields(temp);
  }

  function deleteField(i, array) {
    let temp = [...array];
    temp.splice(i, 1);
    setFields(temp);
  }

  async function Save(e) {
    e.preventDefault();

    fields.forEach((f) => {
      f.post_contract_id = postContractId;
    });

    const payload = {
      projectID: projectID,
      risks: fields,
      postContractID: postContractId,
    };

    const field = JSON.stringify(payload);

    const formData = new FormData();
    formData.append("field", field);

    // POST CONTRACT API
    const url = pc2APIs().set_risks;

    const config = {
      method: "POST",
      body: formData,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };
    setError({
      text: "Saving...",
      type: "feedback-success",
    });
    let response = await fetch(url, config);
    if (response.ok) {
      // Response OK
      setError({
        text: "Saved Successfully",
        type: "feedback-success",
      });

      const URL = window.location.href;
      const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
      postContract.set(
        await postContract.get(projectID, postContractId, sheetName)
      );
    } else {
      // Response Not OK
      console.log("Network Error");
    }
  }
}

export function Notify(props) {
  const { error } = props;

  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{tranStr(error.text)}</b>
      </div>
    </div>
  );
}
