// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Map
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";

import "../portfolio-map/PortfolioMap.css";
import isEmpty from "../../validation/is-empty";
import { MuiAccordion } from "../accordion/MuiAccordion";

/// props validation | SQ(javascript:S6774)
BenchmarkProjectsMap.propTypes = {
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
};
///
export default function BenchmarkProjectsMap(props) {
  const { benchmarks } = props;
  const { thisBenchmark } = props;

  const [centerCoords, setCenterCoords] = useState(null);
  let filteredProjects = benchmarks;
  const { lat, lng } = thisBenchmark;

  useEffect(() => {
    if (!isEmpty(thisBenchmark)) {
      if (!isEmpty(lat) && !isEmpty(lng) && !isNaN(lat) && !isNaN(lng)) {
        setCenterCoords([lat, lng]);
      } else {
        setCenterCoords([0, 0]);
      }
    }
  }, [filteredProjects, thisBenchmark, lng, lat]);

  if (centerCoords === null) {
    return null;
  }
  return (
    <MuiAccordion
      title={"Benchmark Projects' Locations"}
      defaultExpanded={true}
    >
      <div className="metrics-graph-box">
        <div className="portfolio-projects-column">
          <MapContainer
            className="benchmarking-chart-box-dashboard"
            center={centerCoords}
            zoom={2}
            scrollWheelZoom={true}
            attributionControl={false}
          >
            {!isEmpty(lng) && !isEmpty(lat) && (
              <Marker position={[lat, lng]} key={thisBenchmark.id}>
                <Tooltip className={"pm-tooltip"} permanent interactive={true}>
                  <div className="pm-tooltip-text">
                    {thisBenchmark.project_name}
                  </div>
                </Tooltip>
              </Marker>
            )}
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            {filteredProjects.map((p) => {
              if (!isEmpty(p.lat) && !isEmpty(p.lng)) {
                return (
                  <Marker position={[p.lat, p.lng]} key={p.id}>
                    <Tooltip
                      className={"pm-tooltip"}
                      permanent
                      interactive={true}
                    >
                      <div className="pm-tooltip-text">{p.project_name}</div>
                    </Tooltip>
                  </Marker>
                );
              } else {
                return null;
              }
            })}
          </MapContainer>
        </div>
      </div>
    </MuiAccordion>
  );
}
