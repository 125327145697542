import React, { useEffect, useState } from "react";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";

import { projectUpdate } from "../../api/projects/ProjectAPI";
import { tranStr, translate } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import CheckboxesVertical from "../checkboxes/CheckboxesVertical";

import { DisplayError } from "./ProjectControl";

import "./ProjectControlFeatures.css";
import { AutoCloseModal } from "../modal/Modal";

export default function ProjectControlFeatures(props) {
  // Props
  const { project } = props;
  const { setModal } = props;

  // Feature Selection
  const [estimateFeatures, setEstimateFeatures] = useState([]);

  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(project)) {
      // Set Estimate Features
      let tempEstimateFeatures = [];
      if (project.enable_estimate_drawings) {
        tempEstimateFeatures.push({
          label: tranStr("Key Documents"),
          value: "Key Documents",
        });
      }

      if (project.enable_estimate_reconciliation) {
        tempEstimateFeatures.push({
          label: tranStr("Reconciliation"),
          value: "Reconciliation",
        });
      }

      if (project.enable_estimate_benchmarking) {
        tempEstimateFeatures.push({
          label: tranStr("Benchmarking"),
          value: "Benchmarking",
        });
      }

      if (project.enable_estimate_cashflow) {
        tempEstimateFeatures.push({
          label: tranStr("Cashflow"),
          value: "Cashflow",
        });
      }

      if (project.enable_cp_line_references) {
        tempEstimateFeatures.push({
          label: tranStr("Line References"),
          value: "Line References",
        });
      }
      if (project.enable_cp_area_schedule) {
        tempEstimateFeatures.push({
          label: tranStr("Area Schedule"),
          value: "Area Schedule",
        });
      }
      if (project.enable_cp_value_management) {
        tempEstimateFeatures.push({
          label: tranStr("Value Management"),
          value: "Value Management",
        });
      }

      setEstimateFeatures(tempEstimateFeatures);
    }
  }, [project]);

  if (isEmpty(project)) {
    return null;
  }

  const data = {
    // COST PLAN API
    URL: cpAPIs().update_project_features,
    projectData: {
      projectID: project.id,
      estimateFeatures: enabledFeatures(estimateFeatures),
    },
    project: project,
    completeAction: async () => {
      projectUpdate(project);
    },
    setModal: setModal,
    setError: setError,
  };

  // Return
  return (
    <div className="project-control-features">
      <ProjectControlSettings
        label={""}
        features={estimateFeatures}
        setFeatures={setEstimateFeatures}
        options={estimateFeatureOptions()}
      />
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <SaveButton data={data} />
    </div>
  );
}

function SaveButton(props) {
  const { data } = props;

  return (
    <div className="general-button-container">
      <div
        id={"save-project-control-features"}
        className="general-upload-button"
        onClick={() => {
          sendData(data);
        }}
      >
        {translate("Save")}
      </div>
    </div>
  );
}

async function sendData(data) {
  const url = data.URL;
  const payload = JSON.stringify(data.projectData);

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  if (isEmpty(data.project.stages)) {
    data.setError({
      text: tranStr("No CostPlan stages configured for this project"),
      type: "project-stages-error",
    });
    AutoCloseModal(data.setModal);
  } else {
    data.setError({
      text: tranStr("Saving..."),
      type: "project-stages-success",
    });

    let response = await fetch(url, config);
    if (response.ok) {
      // Response OK
      data.completeAction();
      data.setError({
        text: tranStr("Saved Successfully"),
        type: "project-stages-success",
      });

      AutoCloseModal(data.setModal);
    } else {
      // Response Not OK
      data.setError({
        text: tranStr("Save Failed"),
        type: "project-stages-error",
      });
    }
  }
}

function estimateFeatureOptions() {
  return [
    {
      label: tranStr("Key Documents"),
      value: "Key Documents",
    },
    {
      label: tranStr("Reconciliation"),
      value: "Reconciliation",
    },
    {
      label: tranStr("Benchmarking"),
      value: "Benchmarking",
    },
    {
      label: tranStr("Cashflow"),
      value: "Cashflow",
    },
    {
      label: tranStr("Line References"),
      value: "Line References",
    },
    {
      label: tranStr("Value Management"),
      value: "Value Management",
    },
    {
      label: tranStr("Area Schedule"),
      value: "Area Schedule",
    },
  ];
}

function enabledFeatures(array) {
  let features = {
    enable_estimate_drawings: true,
    enable_estimate_reconciliation: true,
    enable_estimate_benchmarking: true,
    enable_estimate_cashflow: true,
    enable_cp_line_references: true,
    enable_cp_value_management: true,
    enable_cp_area_schedule: true,
  };

  if (array.some((e) => e.value === "Key Documents")) {
    features.enable_estimate_drawings = true;
  } else {
    features.enable_estimate_drawings = false;
  }
  if (array.some((e) => e.value === "Reconciliation")) {
    features.enable_estimate_reconciliation = true;
  } else {
    features.enable_estimate_reconciliation = false;
  }
  if (array.some((e) => e.value === "Benchmarking")) {
    features.enable_estimate_benchmarking = true;
  } else {
    features.enable_estimate_benchmarking = false;
  }
  if (array.some((e) => e.value === "Cashflow")) {
    features.enable_estimate_cashflow = true;
  } else {
    features.enable_estimate_cashflow = false;
  }
  if (array.some((e) => e.value === "Line References")) {
    features.enable_cp_line_references = true;
  } else {
    features.enable_cp_line_references = false;
  }
  if (array.some((e) => e.value === "Value Management")) {
    features.enable_cp_value_management = true;
  } else {
    features.enable_cp_value_management = false;
  }
  if (array.some((e) => e.value === "Area Schedule")) {
    features.enable_cp_area_schedule = true;
  } else {
    features.enable_cp_area_schedule = false;
  }

  return features;
}

function ProjectControlSettings(props) {
  const { features, setFeatures } = props;
  const { options } = props;
  const { label } = props;

  return (
    <div className="project-control-settings">
      <div className="project-control-settings-label">
        <b>{label}</b>
      </div>
      <CheckboxesVertical
        array={options}
        setFunction={setFeatures}
        selection={features}
      />
    </div>
  );
}
