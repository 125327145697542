import React from "react";
import generateXLSX from "../../utils/spreadsheets";

export default function ExportEstimate(props) {
  const { estimate } = props;
  let headings = ["Description", "Quantity", "Unit", "Rate", "Total"];
  let data = estimate.rows.map((r) => {
    return [r.description, r.quantity, r.unit_of_measure, r.rate, r.subtotal];
  });
  data.unshift(headings);

  return (
    <div
      className="estimate-export-button"
      onClick={() => {
        generateXLSX(estimate.name, data);
      }}
    >
      <i className="fas fa-download"></i>
    </div>
  );
}

export function ExportEstimatedBreakDown(props) {
  const { estimate } = props;
  let headings = ["Line", "Description", "Quantity", "Unit", "Rate", "Total"];
  let dataTobeExported = [];
  estimate.rows.forEach((r) => {
    dataTobeExported.push([
      r.section,
      r.description,
      r.quantity === 0 ? "" : r.quantity,
      r.unit_of_measure === 0 ? "" : r.unit_of_measure,
      r.rate === 0 ? "" : r.rate,
      r.subtotal === 0 ? "" : r.subtotal,
    ]);
    r.breakdown.forEach((b) => {
      dataTobeExported.push([
        b.breakdown_section,
        b.description,
        b.quantity === 0 ? "" : b.quantity,
        b.unit_of_measure === 0 ? "" : b.unit_of_measure,
        b.rate === 0 ? "" : b.rate,
        b.subtotal === 0 ? "" : b.subtotal,
      ]);
    });
  });
  let data = dataTobeExported;
  data.unshift(headings);
  return (
    <div
      className="estimate-export-button"
      onClick={() => {
        generateXLSX(estimate.name, data);
      }}
    >
      <i className="fas fa-download"></i>
    </div>
  );
}
