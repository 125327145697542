// React
import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";

// Components
import CreateProject from "../project-create/CreateProject";
import ProjectControlEstimateMetrics from "../project-control-estimate-metrics/ProjectControlEstimateMetrics";
import ProjectControlImage from "./ProjectControlImage";
import ProjectPermissions from "../permissions/ProjectPermissions";

import ProjectControlNotify from "./ProjectControlNotify";
import EditRisksOpportunities from "./ProjectControlEditRisksOpportunities";
import EditHighlights from "./ProjectControlEditHighlights";
import ProjectControlFeatures from "./ProjectControlFeatures";
import ProjectControlDrawer from "./ProjectControlDrawer";
// import CreateBenchmarkCostPlan from "../cost-planning-benchmark/CreateBenchmarkCostPlan";
import ReportModal from "../reports/ReportModal";
import CostPlanStages from "./CostPlanStages";
import LocalityFactorOverride from "../cost-planning-control/LocalityFactorOverride";
import PostContractPanel from "../post-contract-2.0/PostContractPanel";

// Generic Components
import AdviceDecisionsEdit from "../dashboard-components/AdviceDecisionsEdit";
import Modal from "../modal/Modal";

// Functions
import { hasRoles } from "../../utils/roles";
import { saveCostPlanDecisions } from "../../api/cost-planning/CostPlanningAdviceAPI";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";
import { projectUpdate } from "../../api/projects/ProjectAPI";
// Style
import "./ProjectControlPanel.css";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";

export default function ProjectControl(props) {
  // Project
  const { auth } = props;
  const { projectLoading } = props;

  // Dashboard
  const { dashboardType, setDashboardType } = props;
  const { showDashboardDrawer, setShowDashboardDrawer } = props;

  const [showProject, setShowProject] = useState(false);
  const [showCostPlan, setShowCostPlan] = useState(false);

  const [showPostContract, setShowPostContract] = useState(false);

  // Only show on Dashboard
  if (!window.location.href.includes("dashboard")) {
    return null;
  }

  if (projectLoading) {
    return null;
  }

  return (
    <div className="project-control-panel">
      <ProjectControlDrawer
        Component={ProjectControls}
        dashboardType={dashboardType}
        dashboardSelect={"DASHBOARD"}
        user={auth.user}
        label={translate("Project Control")}
        show1={showProject}
        setShow1={setShowProject}
        setShow2={setShowCostPlan}
        setShow3={setShowDashboardDrawer}
        setShow4={setShowPostContract}
        {...props}
      />
      <ProjectControlDrawer
        Component={CostPlanControls}
        dashboardType={dashboardType}
        dashboardSelect={"COST_PLANNING"}
        user={auth.user}
        label={translate("Cost Plan Control")}
        show1={showCostPlan}
        setShow1={setShowCostPlan}
        setShow2={setShowProject}
        setShow3={setShowDashboardDrawer}
        setShow4={setShowPostContract}
        {...props}
      />
      <ProjectControlDrawer
        Component={PostContractPanel}
        dashboardType={dashboardType}
        dashboardSelect={"POST_CONTRACT"}
        user={auth.user}
        label={translate("Post Contract Control")}
        show1={showPostContract}
        setShow1={setShowPostContract}
        setShow2={setShowProject}
        setShow3={setShowDashboardDrawer}
        setShow4={setShowCostPlan}
        {...props}
      />
      <ToggleDrawer
        user={auth.user}
        dashboardType={dashboardType}
        dashboardSelect={"COST_PLANNING"}
        label={translate("Project Dashboard")}
        show={showDashboardDrawer}
        setShow1={setShowDashboardDrawer}
        setShow2={setShowCostPlan}
        setShow3={setShowProject}
        setShow4={setShowPostContract}
      />
      <ToggleDrawer
        user={auth.user}
        dashboardType={dashboardType}
        dashboardSelect={"POST_CONTRACT"}
        label={translate("Project Dashboard")}
        show={showDashboardDrawer}
        setShow1={setShowDashboardDrawer}
        setShow2={setShowCostPlan}
        setShow3={setShowProject}
        setShow4={setShowPostContract}
      />
      <div className="project-control-panel-row">
        <ToggleButton
          dashboardType={dashboardType}
          setDashboardType={setDashboardType}
          dashboardSelect={"COST_PLANNING"}
          icon={<i className="fas fa-ruler-combined" />}
          width={"1400px"}
          text={translate("Cost Planning")}
        />
        <ToggleButton
          dashboardType={dashboardType}
          setDashboardType={setDashboardType}
          dashboardSelect={"TENDER"}
          icon={<i className="fas fa-balance-scale" />}
          width={"670px"}
          text={translate("Tender")}
        />
        <ToggleButton
          dashboardType={dashboardType}
          setDashboardType={setDashboardType}
          dashboardSelect={"POST_CONTRACT"}
          icon={<i className="fas fa-file-contract" />}
          width={"1150px"}
          text={translate("Post Contract")}
        />
        <ToggleButton
          dashboardType={dashboardType}
          setDashboardType={setDashboardType}
          dashboardSelect={"Sustainability"}
          icon={<i className="fas fa-leaf" />}
          width={"670px"}
          text={translate("Sustainability")}
        />
      </div>
    </div>
  );
}

function Button(props) {
  const [modal, setModal] = useState(false);
  const { icon, text, enabled } = props;
  const { Component } = props;

  if (!enabled) {
    return (
      <div className="project-control-panel-button-container">
        <div className={"project-control-panel-button-disabled"}>{icon}</div>
        <h1
          className="display-4"
          style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
        >
          {text}
        </h1>
        <Modal
          // Modal Props
          title={text}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          {...props}
        />
        <span className="tooltiptext">{"Returning Soon"}</span>
      </div>
    );
  }

  return (
    <div className="project-control-panel-button-container">
      <div
        className={"project-control-panel-button"}
        onClick={() => {
          setModal(true);
        }}
      >
        {icon}
      </div>
      <h1
        className="display-4"
        style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
      >
        {text}
      </h1>
      <Modal
        // Modal Props
        title={text}
        Component={Component}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}

function ToggleButton(props) {
  const { icon, text } = props;
  const { dashboardType, setDashboardType } = props;
  const { dashboardSelect } = props;

  let classType = "project-control-panel-button";
  if (dashboardType === dashboardSelect) {
    classType = "project-control-panel-button-selected";
  }

  return (
    <div className="project-control-toggle-button-container">
      <div
        className={classType}
        onClick={() => {
          setDashboardType(dashboardSelect);
        }}
      >
        {icon}
      </div>
      <span className="tooltiptext">{text}</span>
    </div>
  );
}

function ToggleDrawer(props) {
  const { show, setShow1, setShow2, setShow3, setShow4 } = props;
  const { label } = props;
  const { dashboardSelect, dashboardType } = props;
  const { user } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (dashboardSelect !== dashboardType) {
    return null;
  }

  let button = "drawer-button";
  if (show) {
    button = "drawer-button-clicked";
  }

  return (
    <div className="project-control-drawer">
      <div
        className={button}
        onClick={() => {
          setShow1(!show);
          setShow2(false);
          setShow3(false);
          setShow4(false);
        }}
      >
        {label}
      </div>
    </div>
  );
}

function ProjectControls(props) {
  const { user } = props;
  const { project, projectID, projectTitle } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="project-control-panel-controls">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Project Panel")}
      </h1>
      <Button
        Component={CreateProject}
        projectID={projectID}
        project={project}
        icon={<i className="fas fa-pen-nib" />}
        width={"1600px"}
        text={translate("Project Details")}
        enabled={true}
        // Component Props
        urlMethod={"PUT"}
        // PROJECT API
        urlEndpoint={projectAPIs().update_project + "/" + project.id}
        user={user}
      />
      <Button
        Component={ProjectControlImage}
        projectID={projectID}
        project={project}
        icon={<i className="fas fa-image" />}
        text={translate("Project Image")}
        enabled={true}
      />
      <Button
        Component={ProjectPermissions}
        project={project}
        icon={<i className="fas fa-user-edit" style={{ marginLeft: "5px" }} />}
        width={"1400px"}
        text={translate("Project Users")}
        enabled={true}
        updateState={async () => {
          await projectUpdate(project);
        }}
      />
      <Button
        Component={ProjectControlNotify}
        projectID={projectID}
        project={project}
        projectTitle={projectTitle}
        icon={<i className="fas fa-envelope" />}
        width={"670px"}
        text={translate("Send Notifications")}
        enabled={true}
      />
    </div>
  );
}

function CostPlanControls(props) {
  const { user } = props;
  const { project, projectID, projectTitle } = props;
  const { CP, CPs } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="project-control-panel-controls">
      <h1 className="display-4">Cost Plan Panel</h1>
      <Button
        Component={CostPlanStages}
        projectID={projectID}
        project={project}
        CPs={CPs}
        icon={<i className="fas fa-bars" />}
        width={"670px"}
        text={translate("Cost Plan Stages")}
        enabled={true}
      />
      <Button
        Component={ProjectControlFeatures}
        projectID={projectID}
        project={project}
        icon={<i className="fas fa-eye-slash" />}
        text={translate("Toggle Features")}
        enabled={true}
      />
      <Button
        Component={ProjectControlEstimateMetrics}
        projectID={projectID}
        project={project}
        CP={CP}
        icon={<i className="fas fa-chart-area"></i>}
        width={"890px"}
        text={translate("Configure Metrics")}
        enabled={true}
      />
      <Button
        Component={EditHighlights}
        CP={CP}
        CPs={CPs}
        projectID={projectID}
        project={project}
        icon={<i className="fas fa-medal" style={{ marginLeft: "1px" }}></i>}
        text={translate("Highlights")}
        enabled={true}
      />
      <Button
        Component={EditRisksOpportunities}
        CP={CP}
        CPs={CPs}
        projectID={projectID}
        project={project}
        icon={
          <i
            className="fas fa-exclamation-triangle"
            style={{ marginBottom: "3px" }}
          ></i>
        }
        text={translate("Risks / Opportunities")}
        enabled={true}
      />
      <Button
        Component={AdviceDecisionsEdit}
        decisions={CP.decisions}
        requiredData={CP}
        saveFunction={saveCostPlanDecisions}
        saveURL={cpAPIs().set_decisions}
        projectID={projectID}
        icon={<i className="fas fa-asterisk"></i>}
        text={translate("Key Decisions")}
        type={"decision"}
        fieldTypes={{
          field1: "advice-decision",
          field2: "advice-required-by",
        }}
        labels={{
          label1: tranStr("Decision"),
          label2: tranStr("Required Date (DD/MM/YYYY)"),
        }}
        note={tranStr(
          "Add up to 5 key Decisions below to be displayed on the Cost Planning Dashboard."
        )}
        max={5}
        data={{
          CP: CP,
          CPs: CPs,
          projectID: projectID,
          project: project,
          type: "decision",
        }}
        enabled={true}
      />
      <Button
        Component={AdviceDecisionsEdit}
        decisions={CP.decisions}
        requiredData={CP}
        saveFunction={saveCostPlanDecisions}
        saveURL={cpAPIs().set_decisions}
        projectID={projectID}
        icon={<i className="fas fa-file-alt"></i>}
        text={translate("Contractual Glossary")}
        type={"glossary"}
        fieldTypes={{ field1: "advice-glossary", field2: "advice-glossary" }}
        labels={{
          label1: tranStr("Reporting Term"),
          label2: tranStr("Project Specific or Contractual Term"),
        }}
        note={tranStr(
          "Use the fields below to clarify how reporting terms used in Cost Clarity relate to project specific and/or contractual terms."
        )}
        max={20}
        data={{
          CP: CP,
          CPs: CPs,
          projectID: projectID,
          project: project,
          type: "glossary",
        }}
        enabled={true}
      />
      {/* <Button
        Component={CreateBenchmarkCostPlan}
        project={project}
        CP={CP}
        icon={<i className="fas fa-plus" />}
        width={"1000px"}
        text={translate("Convert Cost Plan to Benchmark")}
        enabled={false}
      /> */}
      <Button
        Component={LocalityFactorOverride}
        projectID={projectID}
        project={project}
        projectTitle={projectTitle}
        projectPhase={"COST_PLANNING"}
        CP={CP}
        icon={<i className="far fa-map" />}
        width={"10000px"}
        text={translate("Locality Factor Override")}
        data={{
          CP: CP,
          projectID: projectID,
        }}
        enabled={true}
      />
      <Button
        Component={ReportModal}
        projectID={projectID}
        project={project}
        projectTitle={projectTitle}
        projectPhase={"COST_PLANNING"}
        CP={CP}
        icon={<i className="fas fa-download" />}
        width={"1000px"}
        text={translate("Export Cost Plan")}
        enabled={true}
        type={"Cost Plan"}
      />
    </div>
  );
}

export function DisplayError(props) {
  const { error } = props;
  return (
    <div className="project-stages-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
