import React from "react";
import generateXLSX from "../../utils/spreadsheets";

// API
export default function ExportLocalisation(props) {
  const { language } = props;
  const { selectedNamespace } = props;
  const { inputMode } = props;

  if (inputMode !== "EXPORT") {
    return null;
  }

  //headings for the excel document
  let headings = ["Keys", "Values"];
  const keys = Object.keys(language);

  //traverse through the keys from language json object and get key value
  let data = keys.map((r) => {
    return [r, language[r]];
  });

  data.unshift(headings);

  return (
    <div className="alc-container">
      <div className="alc-row">
        <b>Export Terminology</b>
      </div>
      <div className="alc-row">
        The currently selected terminology collection can be exported to Excel
        with the button below.
      </div>
      <div className="alc-column">
        <div className="general-row-container"></div>
        <div className="general-row-container">
          <div
            className="general-upload-button"
            onClick={() => {
              generateXLSX(selectedNamespace, data);
            }}
          >
            Export Set
          </div>
        </div>
      </div>
    </div>
  );
}
