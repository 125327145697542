import React from "react";
import { translate } from "../../utils/translation";

export function Toggle(props) {
  const { isElementalUnitRate, setIsElementalUnitRate } = props;

  let local_region_area;
  let unit;
  if (isElementalUnitRate === false) {
    local_region_area = "bench-recon-comarison-toggle-button-in-left";
    unit = "bench-recon-comarison-toggle-button-out-right";
  } else {
    local_region_area = "bench-recon-comarison-toggle-button-out-left";
    unit = "bench-recon-comarison-toggle-button-in-right";
  }

  return (
    <div className="recon-comparison-toggle">
      <div
        id={"benchmark-project-toggle-button-area"}
        className={local_region_area}
        onClick={() => {
          setIsElementalUnitRate(false);
        }}
      >
        <h1 className="display-4">{translate("Area")}</h1>
      </div>
      <div
        id={"benchmark-project-toggle-button-unit"}
        className={unit}
        onClick={() => {
          setIsElementalUnitRate(true);
        }}
      >
        <h1 className="display-4">{translate("Unit")}</h1>
      </div>
    </div>
  );
}
