// Import React
import React, { useState } from "react";

import { hasRoles } from "../../utils/roles";
import { ArticleEditModal } from "./ArtcleEditModal";

import Modal from "../modal/Modal";

import "./CategoryEdit.css";
import "./CategoryDelete.css";

export default function ArticleEdit(props) {
  const { selectedArticle } = props;
  const { setInsights } = props;
  const { user } = props;
  const { isVisible } = props;
  const { region, country } = props;

  const [modal, setModal] = useState(false);

  const URL = window.location.href;

  if (!URL.includes("article/") && !isVisible) {
    return null;
  }
  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  return (
    <div className="articles-card-button-container">
      <div
        id={"article-edit-button"}
        className="article-delete-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-pencil-alt" />
      </div>
      <span className="tooltiptext">Edit Article</span>
      <Modal
        // Modal Props
        title={"Edit Article"}
        Component={ArticleEditModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedArticle={selectedArticle}
        show={modal}
        setShow={setModal}
        setInsights={setInsights}
        user={user}
        region={region}
        country={country}
      />
    </div>
  );
}
