import React, { useState } from "react";

import { tranStr } from "../../utils/translation";

export function PortfolioInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "benchmark-selection-filter-input-icon";
  if (focus || value !== "")
    iconType = "benchmark-selection-filter-input-icon-focus";

  return (
    <div className="benchmark-selection-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="benchmark-selection-filter-input-col">
        <div className="benchmark-selection-filter-input-heading">
          {placeholder}
        </div>
        <div className="benchmark-selection-filter-input-row">
          <input
            className="benchmark-selection-filter-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

export function BenchmarkModeToggle(props) {
  const { value, onChange, placeholder, icon } = props;

  return (
    <div className="benchmark-selection-filter-input-container">
      <div className={"benchmark-selection-filter-input-icon"}>{icon}</div>
      <div className="benchmark-selection-filter-input-col">
        <div className="benchmark-selection-filter-input-heading">
          {placeholder}
        </div>
        <div className="benchmark-selection-filter-toggle-row">
          <label className="switch">
            <input
              type="checkbox"
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
            <span className="slider round" />
          </label>
        </div>
      </div>
    </div>
  );
}

export function PortfolioSelectSingle(props) {
  const { value, onChange, placeholder, icon, options } = props;
  const { setChildSelection } = props;

  const [focus, setFocus] = useState(false);
  let iconType = "benchmark-selection-filter-input-icon";
  if (focus || value !== "")
    iconType = "benchmark-selection-filter-input-icon-focus";

  return (
    <div className="benchmark-selection-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="benchmark-selection-filter-input-col">
        <div className="benchmark-selection-filter-input-heading">
          {placeholder}
        </div>
        <div className="benchmark-selection-filter-input-row">
          <select
            className="benchmark-selection-filter-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              if (setChildSelection) {
                setChildSelection("");
              }
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(options)}
          </select>
        </div>
      </div>
    </div>
  );

  function selectOptions(availableOptions) {
    return availableOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.label}
      </option>
    ));
  }
}

export function PortfolioSelectSingleFilter(props) {
  const { value, onChange, placeholder, icon, options } = props;
  const { filter } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "benchmark-selection-filter-input-icon";
  if (focus || value !== "")
    iconType = "benchmark-selection-filter-input-icon-focus";

  return (
    <div className="benchmark-selection-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="benchmark-selection-filter-input-col">
        <div className="benchmark-selection-filter-input-heading">
          {placeholder}
        </div>
        <div className="benchmark-selection-filter-input-row">
          <select
            className="benchmark-selection-filter-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(options)}
          </select>
        </div>
      </div>
    </div>
  );

  function selectOptions(availableOptions) {
    return availableOptions.map((option) => {
      if (option.type === filter || option.type === "") {
        return (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        );
      } else {
        return null;
      }
    });
  }
}

export function PortfolioInputDouble(props) {
  const { value1, onChange1 } = props;
  const { value2, onChange2 } = props;
  const { placeholder, icon } = props;
  const [focus, setFocus] = useState(false);

  let iconType = "benchmark-selection-filter-input-icon";
  if (focus || value1 !== "" || value2 !== "")
    iconType = "benchmark-selection-filter-input-icon-focus";

  return (
    <div className="benchmark-selection-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="benchmark-selection-filter-input-col">
        <div className="benchmark-selection-filter-input-heading">
          {placeholder}
        </div>
        <div className="benchmark-selection-filter-input-row">
          <input
            className="benchmark-selection-filter-input-pair"
            type="text"
            value={value1}
            onChange={(e) => {
              onChange1(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder={tranStr("Min")}
          ></input>
          <input
            className="benchmark-selection-filter-input-pair-second"
            type="text"
            value={value2}
            onChange={(e) => {
              onChange2(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder={tranStr("Max")}
          ></input>
        </div>
      </div>
    </div>
  );
}
