// Import React
import React, { useState } from "react";
import "./CategoryDelete.css";
import { hasRoles } from "../../utils/roles";
import { deleteCategory } from "./MarketInsights.functions";
import Spinner from "../common/Spinner";

export default function CategoryDelete(props) {
  const { selectedTopic } = props;
  const { setInsights } = props;
  const { user } = props;

  let subLocation = determineSubLocation();

  const [loading, setLoading] = useState(false);

  if (subLocation !== "articles") {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  if (loading) {
    return <Spinner width={"35px"} height={"35px"} />;
  }

  return (
    <div className="insights-control-panel-button-container">
      <div
        className="category-delete-button"
        id={"insight-category-delete"}
        onClick={async () => {
          setLoading(true);
          await deleteCategory(selectedTopic.id, setInsights);
        }}
      >
        <i className="fas fa-trash-alt" />
      </div>
      <span className="tooltiptext">Delete Category</span>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
