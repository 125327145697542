import { displayUserImage } from "../../validation/image-clean";
import defaultLogo from "../../img/default-logo.png";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { ImageDisplay } from "../images/ImageDisplay";

export function UploadFile(props) {
  const { setFile } = props;
  const { setExistingLogo } = props;

  return (
    <label className="general-modal-button">
      <input
        id="company-logo-input"
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setExistingLogo(window.URL.createObjectURL(e.target.files[0]));
          }
        }}
      />
      {translate("Attach Logo")}
    </label>
  );
}

export function FileTray(props) {
  const { file } = props;
  let fileName = "Keep Existing";

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="company-create-file-display">
      <b>{translate("Logo")}:</b> {fileName}
    </div>
  );
}

export function DisplayNameUser(props) {
  const { user } = props;

  if (isEmpty(user)) {
    return null;
  }

  return (
    <div className="pciu-row">
      <div className="pciu-name"></div>

      <ImageDisplay
        image={displayUserImage(user)}
        imageWidth={"30px"}
        imageHeight={"30px"}
        borderRadius={"15px"}
        isBezierDisabled={true}
      />

      <div className="pciu-name">{user.name}</div>
      <div className="pciu-name">{user.surname}</div>
    </div>
  );
}

export function ExistingFile(props) {
  const { existingLogo } = props;

  return (
    <img
      className="company-create-existing-logo"
      src={existingLogo}
      onError={(e) => {
        if (e.target.src !== defaultLogo) {
          e.target.src = defaultLogo;
        } else {
          e.target.src = null;
        }
      }}
      alt=""
      title="Cost Clarity Image"
    />
  );
}

export function Upload(props) {
  const { data } = props;
  const { uploadFile } = props;
  return (
    <div
      id="comapny-upload-button"
      className="general-upload-button"
      onClick={async () => {
        uploadFile(data);
      }}
    >
      {translate("Save")}
    </div>
  );
}

export function DisplayError(props) {
  const { error } = props;
  return (
    <div className="company-create-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

export function onConsent(event, set) {
  const value = event.target.checked;
  set(value);
}
