import React from "react";

import { PCRA_MARKUP } from "./PCRAGlossary.constants";
const { SHOW_HIDE_PAST_AUDIT_TRAIL_TOGGLE_DISPLAY } = PCRA_MARKUP;

export function PCRAShowPastJourneysToggle(props) {
  const { showPastJourneys, setShowPastJourneys } = props;

  const { icon, iconText, displayText } =
    SHOW_HIDE_PAST_AUDIT_TRAIL_TOGGLE_DISPLAY[showPastJourneys];

  return (
    <div
      className="pcra-show-past-journeys-toggle"
      onClick={() => setShowPastJourneys(!showPastJourneys)}
    >
      <i className={icon}></i>
      <div className="icon-text">{iconText}</div>
      <div className="display-text">{displayText}</div>
    </div>
  );
}
