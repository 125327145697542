import React from "react";
import i18n from "i18next";
import { translate } from "../../utils/translation";
import "./LanguageSelect.css";

export default function LanguageSelect() {
  const ENABLE_TRANSLATION = false;

  if (!ENABLE_TRANSLATION) {
    return null;
  }

  return (
    <div className="language-select">
      <div className="dropdown">
        <button className="dropbtn">
          <i className="fas fa-flag" />
          <div>{translate("Language")}</div>
          <div className="dropbtn-caret">
            <i className="fa fa-caret-down" />
          </div>
        </button>
        <div className="dropdown-content">
          <div className="language-select-heading"></div>
          <div className="language-select-row">
            <div
              className="language-select-button"
              onClick={() => {
                i18n.changeLanguage("en");
                // Force reload so that project data is pulled
                window.location.reload();
              }}
            >
              English
            </div>
          </div>
          <div className="language-select-row">
            <div
              className="language-select-button"
              onClick={() => {
                i18n.changeLanguage("zh");
                // Force reload so that project data is pulled
                window.location.reload();
              }}
            >
              {"中文（简体)"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
