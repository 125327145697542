// Import React
import React, { useState, useEffect } from "react";

import { editCategory } from "./MarketInsights.functions";

import isEmpty from "../../validation/is-empty";

import { Heading, DisplayError } from "./MarketInsights.components";
import { ImageUpload } from "../images/ImageUpload";

export function CategoryEditModal(props) {
  const { selectedTopic } = props;
  const { setShow } = props;
  const { setInsights } = props;

  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(selectedTopic)) {
      setTitle(selectedTopic.title);
      setImagePreview(selectedTopic.image);
    }
  }, [selectedTopic]);

  return (
    <div className="category-edit">
      <h1 className="display-4" style={{ fontSize: "30px" }}>
        Edit Category
      </h1>

      <Heading title={title} />
      <div className="general-row-container">
        <input
          id={"insight-category-edit-title"}
          value={title}
          placeholder={"Title"}
          onChange={(e) => {
            setTitle(e.target.value);
            setError({});
          }}
        />
      </div>
      <div className="general-row-container">
        <ImageUpload
          imagePreview={imagePreview}
          setImage={setImage}
          setImagePreview={setImagePreview}
          imageWidth={"460px"}
          imageHeight={"300px"}
        />
      </div>

      <div className="general-row-container">
        <DisplayError error={error} />
      </div>

      <div className="general-button-container">
        <div
          id={"button-category-submit"}
          className="general-upload-button"
          onClick={() => {
            editCategory(
              image,
              title,
              setError,
              setShow,
              selectedTopic,
              setInsights
            );
          }}
        >
          Update
        </div>
      </div>
    </div>
  );
}
