// React
import React, { useState } from "react";
import PropTypes from "prop-types";

import { createProject } from "../../api/projects/ProjectCreateAPI";
import { useNamespaces } from "../../api/admin/LocalisationsAPI";
import Modal from "../modal/Modal";

// Functions
import isEmpty from "../../validation/is-empty";

// Styles
import defaultLogo from "../../img/default-logo.png";

import { translate, tranStr } from "../../utils/translation";

export function ErrorResponse(props) {
  const { error } = props;
  if (isEmpty(error)) {
    return null;
  }

  let errorType = "feedback-success";
  if (error.error) {
    errorType = "feedback-error";
  }
  return (
    <div className="feedback-error-container">
      <div className={errorType}>
        <b>{error.message}</b>
      </div>
    </div>
  );
}

export function Submit(props) {
  const { data } = props;
  const { disableSubmit } = props;
  const { error } = props;

  return (
    <div>
      <div>{error}</div>

      <button
        className="create-project-submit-button"
        disabled={disableSubmit}
        onClick={() => {
          createProject(data);
        }}
      >
        {translate("Save")}
      </button>
    </div>
  );
}

export function FieldLimited(props) {
  const { label } = props;
  const { placeholder } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;

  let charCount = "invalid-char-counter";

  if (value.length > 2 && value.length <= 30) {
    charCount = "char-counter";
  }

  return (
    <div className="create-project-input-row">
      <div className="create-project-input-limit">
        <div>{label} - (Characters Remaining:</div>
        <div className={charCount}>{value.length} / 30</div>)
      </div>
      <input
        className="create-project-input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      ></input>
      <DisplayError error={error} />
    </div>
  );
}

export function Field(props) {
  const { label } = props;
  const { placeholder } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { classType } = props;

  let type = "create-project-input";
  if (!isEmpty(classType)) {
    type = classType;
  }

  return (
    <div className="create-project-input-row">
      <Label label={label} />

      <input
        className={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      ></input>
      <DisplayError error={error} />
    </div>
  );
}

export function Select(props) {
  const { value, onChange, options, label } = props;
  const { error } = props;
  const { classType } = props;
  const { iconToolTip, icon } = props;

  let type = "create-project-select";
  if (!isEmpty(classType)) {
    type = classType;
  }

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      {iconToolTip && (
        <i className={`${icon} icon`} title={tranStr(iconToolTip)}></i>
      )}
      <select
        className={type}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        <SelectList options={options} />
      </select>
      <DisplayError error={error} />
    </div>
  );
}

export function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.label}
      </option>
    );
  });
}

export function SelectFilter(props) {
  const { value, onChange, options, label } = props;
  const { error } = props;
  const { filter } = props;
  const { classType } = props;

  let type = "create-project-select";
  if (!isEmpty(classType)) {
    type = classType;
  }

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      <select
        className={type}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        <SelectListFilter options={options} filter={filter} />
      </select>
      <DisplayError error={error} />
    </div>
  );
}

export function SelectListFilter(props) {
  const { options } = props;
  const { filter } = props;
  return options.map((item, i) => {
    if (item.type === filter || item.type === "") {
      return (
        <option key={i} value={item.value} className="option">
          {item.label}
        </option>
      );
    } else {
      return null;
    }
  });
}

export function DisplayError(props) {
  const { error } = props;

  if (isEmpty(error)) {
    return null;
  }

  return <div className="create-project-modal-error">{error}</div>;
}

/// props validation | SQ(javascript:S6774)
ModalCompany.propTypes = {
  Component: PropTypes.func,
  setFunction: PropTypes.func,
  label: PropTypes.any,
  title: PropTypes.string,
  company: PropTypes.object,
  error: PropTypes.any,
  isProjectCreation: PropTypes.bool,
};
///
export function ModalCompany(props) {
  const { Component, setFunction, label, title } = props;
  const { company, error } = props;
  const { isProjectCreation } = props;

  const [modal, setModal] = useState(false);

  return (
    <div className="create-project-col">
      <div className="create-project-row">
        {isProjectCreation && (
          <>
            <Modal
              // Modal Props
              title={title}
              Component={Component}
              modal={modal}
              setModal={setModal}
              // Component Props
              show={modal}
              setShow={setModal}
              setFunction={setFunction}
              label={label}
            />
            <div
              className="create-project-modal-button"
              onClick={() => {
                setModal(true);
              }}
            >
              {label}
            </div>
          </>
        )}
        <DisplayNameCompany company={company} />
      </div>
      <DisplayError error={error} />
    </div>
  );
}

export function Label(props) {
  const { label } = props;
  return (
    <div className="create-project-label">
      <div>{label}</div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
DisplayNameCompany.propTypes = {
  company: PropTypes.object,
};
///
function DisplayNameCompany(props) {
  const { company } = props;

  if (isEmpty(company)) {
    return null;
  }
  return (
    <div className="create-project-company-logo-and-name">
      <div className="create-project-company-logo">
        <img
          src={company.logo || ""}
          onError={(e) => {
            if (e.target.src !== defaultLogo) {
              e.target.src = defaultLogo;
            } else {
              e.target.src = null;
            }
          }}
          alt=""
          title="Cost Clarity Image"
        />
      </div>
      <span className="create-project-company-name">{company.name}</span>
    </div>
  );
}

export function SelectLocalisation(props) {
  const { namespacesObject } = useNamespaces();

  const { value, setValue, label } = props;
  const { error } = props;

  // Trim language code 'en-'
  const trimmed = namespacesObject.data.map((x) => x.slice(3));
  // Unique array of terminologies from set
  const unique = Array.from(new Set(trimmed.map((x) => x)));
  // List of options
  const list = [];

  // Populate the list of items
  unique.forEach((x) => {
    list.push({ value: x, label: x });
  });

  // Add blank item at the top. Blank values cannot be null
  list.unshift({ value: "", label: tranStr("Select Localisation") });

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      <select
        className="create-project-select"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        {list.map((item, i) => {
          return (
            <option key={i} value={item.value} className="option">
              {item.label}
            </option>
          );
        })}
      </select>
      <DisplayError error={error} />
    </div>
  );
}

export function FieldNumber(props) {
  const { label } = props;
  const { placeholder } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      <input
        className="create-project-input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          // Only permit numbers and "-" and "." characters
          if (
            !isNaN(e.target.value) ||
            e.target.value === "-" ||
            e.target.value === "."
          ) {
            onChange(e.target.value);
          }
        }}
      ></input>
      <DisplayError error={error} />
    </div>
  );
}
