import React, { useState } from "react";

import { uploadOptionsAnalysis } from "./OptionsAnalysis.functions";
import OptionsAnalysisUploader from "./OptionsAnalysisUploader";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "./OptionsAnalysisUploadModal.css";

export default function OptionsAnalysisUploadModal(props) {
  const { user, project, CPs, CP } = props;
  const { options, getOptions, setOptions } = props;

  const [modal, setModal] = useState(false);

  const subLocation = getSubLocation();

  if (
    subLocation !== "options-analysis" ||
    !hasRoles(user.roles, ["CostManager"])
  ) {
    return null;
  }

  const data = {
    projectID: project.id,
    project,
    CP,
    CPs,
    setModal,
    options,
    getOptions,
    setOptions,
    stageId: null,
  };

  return (
    <div>
      <div
        className="options-analysis-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Upload Option Items")}
      </div>
      <Modal
        title={translate("Upload Options Analysis Items")}
        Component={OptionsAnalysisUploader}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        uploadFunction={uploadOptionsAnalysis}
        isOptionsAnalysis={true}
        {...props}
      />
    </div>
  );
}

function getSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
