import React, { useState } from "react";

import Modal from "../modal/Modal";

import { hasRoles } from "../../utils/roles";
import { tranStr } from "../../utils/translation";
import { bmAPIs } from "../../api/api-endpoints/BenchmarkingEndpoints";

import "../estimates/EstimatesEdit.css";
import CreateBenchmarkQuick from "./CreateBenchmarkQuick";

export default function CreateBenchmarkEstimateModal(props) {
  const { project } = props;
  const { estimate } = props;
  const { user } = props;
  const { CP, CPs } = props;

  const [modal, setModal] = useState(false);

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="estimate-edit-wrapper">
      <Modal
        // Modal Props
        Component={CreateBenchmarkQuick}
        title={tranStr("Convert Estimate to Benchmark")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        estimate={estimate}
        CP={CP}
        project={project}
        CPs={CPs}
        user={user}
        // Form Settings
        rowsFormIsEnabled={false}
        metricsFormIsEnabled={false}
        mappingFunction={async () => null}
        exclusiveProjectId={null}
        url={bmAPIs().create_benchmark}
        benchmarkSource={"CONVERSION"}
      />
      <div
        id={"button-budget-allocation"}
        className="estimate-edit-button"
        onClick={() => {
          setModal(true);
        }}
        title="Convert Estimate to Benchmark"
      >
        <i className="fas fa-random"></i>
      </div>
    </div>
  );
}
