import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

import "./PortfolioFilterWarning.css";

export default function PortfolioFilterWarning(props) {
  const { portfolioFilters, setPortfolioFilters } = props;
  let displayWarning = false;
  Object.keys(portfolioFilters).forEach((key) => {
    if (!isEmpty(portfolioFilters[key])) {
      displayWarning = true;
    }
  });

  if (!displayWarning) {
    return null;
  }

  return (
    <div className="portfolio-filter-warning-row">
      <div
        className="portfolio-filter-warning-container"
        onClick={() => {
          const emptyFilters = {};
          Object.keys(portfolioFilters).forEach((key) => {
            emptyFilters[key] = "";
          });

          setPortfolioFilters(emptyFilters);
        }}
      >
        <div className="portfolio-filter-warning">
          <i className="fas fa-exclamation-triangle"></i>
        </div>
        <div className="portfolio-filter-warning">
          {translate("Clear Project Filters")}
        </div>
      </div>
    </div>
  );
}
