import { detectDeployment } from "./Utilities";

export function pc2APIs() {
  const config = {
    serviceAPI: true,
    localService: "https://localhost:44380/v1.0",
    deployedService: "/postcontract/apis/postcontractservice/v1.0",
  };

  // Deployed Dev
  // "https://dev.cost-clarity.com/postcontract/apis/postcontractservice/v1.0"
  // "https://test.cost-clarity.com/postcontract/apis/postcontractservice/v1.0"

  // Local Dev
  //  "https://localhost:44380/v1.0"

  const D = detectDeployment(config);
  return {
    // Post Contract 2.0
    read_packages: `${D}/post-contract/read_packages`,
    update_packages: `${D}/post-contract/update_packages`,

    read_contract_fields: `${D}/post-contract/read_contract_fields`,
    update_contract_fields: `${D}/post-contract/update_contract_fields`,

    read_section_fields: `${D}/post-contract/read_section_fields`,
    update_section_fields: `${D}/post-contract/update_section_fields`,

    read_upload_form: `${D}/post-contract/read_upload_form`,

    read_reports: `${D}/post-contract/read_post_contract_reports`,

    upload_form: `${D}/post-contract/upload`,
    read_post_contract: `${D}/post-contract/read_post_contract`,

    // Dashboard Layout
    save_layout: `${D}/projects-dashboard-settings/save_layout`,
    get_layout: `${D}/projects-dashboard-settings/get_layout`,

    // Delete Post Contract
    delete_post_contract: `${D}/post-contract/delete`,

    // Clarifications / Exclusions / Progress Claim Commentary
    save_commentary: `${D}/post-contract-advice/save_commentary`,

    // Risks
    set_risks: `${D}/post-contract-advice/set_risks`,

    // Decisions
    set_decisions: `${D}/post-contract-advice/set_decisions`,

    // Commentary
    commentary_delete: `${D}/costreport-commentary/commentary/delete`,
    commentary_edit: `${D}/costreport-commentary/commentary/edit`,
    commentary_create: `${D}/costreport-commentary/commentary/create`,
    commentary_upload_table: `${D}/costreport-commentary/commentary/upload_table`,

    // Cashflow
    save_cashflow: `${D}/post-contract/update_cashflow`,

    //Critical
    save_critical_variations: `${D}/post-contract/update_critical_records`,

    // System Dashboard
    get_cost_report_stats: `${D}/post-contract/get_cost_report_stats`,

    // Project Admin
    delete_all_post_contract: `${D}/projects-decoupled-control/delete_post_contract`,

    //Portfolio
    get_projects_post_contract: `${D}/projects/get_projects_post_contract`,
  };
}
