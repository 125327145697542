import React from "react";

import isEmpty from "../../validation/is-empty";

import {
  AccordionDescription,
  AccordionTotal,
  AccordionRates,
} from "./Accordion.components";

import "./Accordion.css";

export default function Accordion(props) {
  // Props
  const { label, local_region_area, unit, rate, total } = props;
  const { children } = props;
  const { isOpen } = props;

  // State
  const { estimateID } = props;
  const { accordionState, accordionSection } = props;

  if (isEmpty(accordionState.state)) {
    return null;
  }

  let open;
  if (isOpen) {
    // Check if Accordion is forced open
    open = true;
  } else {
    // Otherwise check the state
    open = accordionState.state[accordionSection][estimateID];
  }

  // If estimateID = -1, this is for the Total row
  const isTotalRow = estimateID === -1;

  return (
    <div className="accordion-container">
      <div
        className={`accordion-button ${isTotalRow ? "total-row" : ""}`}
        onClick={() => {
          accordionState.set(() => {
            let copy = { ...accordionState.state };
            accordionState.state[accordionSection][estimateID] =
              !accordionState.state[accordionSection][estimateID];

            return copy;
          });
        }}>
        <AccordionDescription label={label} show={open} />
        <div className="accordion-costs">
          <AccordionRates
            local_region_area={local_region_area}
            unit={unit}
            rate={rate}
          />
          <AccordionTotal total={total} />
        </div>
      </div>
      {open && <div>{children}</div>}
    </div>
  );
}
