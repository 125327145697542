import React from "react";
import "./SystemDashboard.css";

export default function SystemDashboardToggle(props) {
  const { inputMode, setInputMode } = props;

  let costPlanToggle;
  let costReportToggle;

  if (inputMode === "COSTPLAN") {
    costPlanToggle = "toggle-active-button";
    costReportToggle = "toggle-inactive-button";
  }
  if (inputMode === "COSTREPORT") {
    costPlanToggle = "toggle-inactive-button";
    costReportToggle = "toggle-active-button";
  }
  return (
    <div className="systemDashboard-toggle">
      <div
        className={costPlanToggle}
        onClick={async () => {
          setInputMode("COSTPLAN");
        }}
      >
        Cost Plan
      </div>
      <div
        className={costReportToggle}
        onClick={async () => {
          setInputMode("COSTREPORT");
        }}
      >
        Cost Report
      </div>
    </div>
  );
}
