import React from "react";
import generateXLSX from "../../utils/spreadsheets";

export default function ExportBenchmarkHeatMap(props) {
  const { rows, projectName, columns } = props;
  let headings = columns.map((p) => {
    return p.project_name;
  });
  let data = rows.map((r) => {
    return r.cells.map((c) => c.field);
  });
  data.unshift(headings);
  return (
    <div
      className="estimate-export-button"
      onClick={() => {
        generateXLSX(projectName + " Heatmap Data", data);
      }}
    >
      <i className="fas fa-download"></i>
    </div>
  );
}
