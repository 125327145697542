import React, { useState } from "react";
import Modal from "../modal/Modal";

import { InsightArticleUploadModal } from "./InsightArticleUploadModal";

import "./MarketInsightsUpload.css";
import { hasRoles } from "../../utils/roles";

export default function InsightArticleUpload(props) {
  const { selectedTopic, user } = props;
  const { setInsights } = props;
  const { region, country } = props;

  const [modal, setModal] = useState(false);

  let subLocation = determineSubLocation();

  if (subLocation !== "articles") {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  return (
    <div className="insights-control-panel-button-container">
      <div
        id="article-upload-button"
        className="category-delete-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-plus"></i>
      </div>
      <span className="tooltiptext">Add Article</span>
      <Modal
        // Modal Props
        title={"New Article"}
        Component={InsightArticleUploadModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        user={user}
        selectedTopic={selectedTopic}
        show={modal}
        setShow={setModal}
        setInsights={setInsights}
        region={region}
        country={country}
      />
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
