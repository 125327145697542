import React from "react";
import Countdown from "react-countdown";
import { logOutUser } from "./LoginLogout.functions";
import "./ModalComponent.css";

function ModalComponent(props) {
  const { setModal, logoutAfter } = props;

  //close the modal
  const stayActive = () => {
    setModal(false);
  };

  //logout the user by clearing browser variables
  const logOut = () => {
    logOutUser();
    setModal(false);
  };

  // Renderer callback with condition
  const renderer = ({ total, completed }) => {
    if (completed) {
      // Render a completed state
      logOut();
      //set this variable to display a message to user that they have been logged out due to inactivity
      localStorage.setItem("idleLogout", true);
      return null;
    } else {
      // Render a countdown
      return (
        <div>
          You have been inactive for a while and will be logged out in{" "}
          <b>{Math.round(total / 1000)}</b> seconds.
        </div>
      );
    }
  };

  return (
    <div className="idle-warning">
      <div>
        <Countdown
          date={Date.now() + logoutAfter}
          intervalDelay={1000}
          precision={1}
          renderer={renderer}
        />
      </div>
      <div className="idle-warning-button-container">
        <div
          className="idle-warning-logout-button"
          onClick={() => {
            logOut();
          }}
        >
          Logout
        </div>
        <div
          className="idle-warning-cancel-button"
          onClick={() => {
            stayActive();
          }}
        >
          Cancel
        </div>
      </div>
    </div>
  );
}

export default ModalComponent;
