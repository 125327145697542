import "./ImageDisplay.css";

export function ImageDisplay(props) {
  const { imageWidth, imageHeight } = props;
  const { image } = props;
  const { borderRadius } = props;
  const { isBezierDisabled } = props;

  let classType = "image-display-bezier";

  if (isBezierDisabled) {
    classType = "image-display";
  }

  return (
    <div
      className={classType}
      style={{
        height: imageHeight,
        width: imageWidth,
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(248, 247, 216, 0.7)",
        borderRadius: borderRadius || "0px",
      }}
    ></div>
  );
}
