// React
import React, { useState, useEffect } from "react";

// API
import { savePortfolioData } from "../../api/portfolio/PortfolioAPI";

// Components
import { tranStr } from "../../utils/translation";
import Modal from "../modal/Modal";
import PortfolioPermissions from "../permissions/PortfolioPermissions";

// Style
import "./PortfolioEdit.css";

export default function PortfolioEdit(props) {
  const { isEditEnabled, setIsEditEnabled } = props;
  const { selectedPortfolio } = props;
  const { portfolios } = props;
  const { users } = props;

  const [name, setName] = useState("");

  useEffect(() => {
    if (selectedPortfolio) {
      setName(selectedPortfolio.name);
    }
  }, [selectedPortfolio]);

  if (!isEditEnabled) {
    return null;
  }

  return (
    <div className="portfolio-edit">
      <div className="general-row-container">
        <EditPermissions
          selectedPortfolio={selectedPortfolio}
          portfolios={portfolios}
          users={users}
        />
      </div>
      <div className="general-row-container">
        <input
          id={"id-portfolio-edit-name-input"}
          className="portfolio-edit-controls-input"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="general-row-container">
        <div className="general-row-container">
          <div
            className="general-upload-button"
            onClick={async () => {
              // Update name field, and send entire object
              selectedPortfolio.name = name;
              await savePortfolioData(selectedPortfolio);
              setIsEditEnabled(false);
              portfolios.set(await portfolios.get());
            }}
          >
            {tranStr("Update Name")}
          </div>
        </div>
      </div>
    </div>
  );
}

function EditPermissions(props) {
  const { selectedPortfolio } = props;
  const { users } = props;

  const [modal, setModal] = useState(false);
  return (
    <div>
      <Modal
        // Modal Props
        title={"Edit Permissions"}
        Component={PortfolioPermissions}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedPortfolio={selectedPortfolio}
        portfolioUsers={users}
        updateState={async () => {
          users.set(await users.get(selectedPortfolio.id, "Portfolio", "all"));
        }}
      />
      <div
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {"Share Portfolio"}
      </div>
    </div>
  );
}
