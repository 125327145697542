import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";

import "./OptionsAnalysisEditStages.css";
import "./OptionsAnalysisStages.css";

import { submitStages } from "./OptionsAnalysisStages.functions";
import { translate } from "../../utils/translation";
import { useLoadOptionsStages } from "./OptionsAnalysisAPI";
import GenerateFields from "./OptionsAnalysisGenerateFields";

export default function OptionsAnalysisStages(props) {
  const { projectID } = props;
  const { project } = props;
  const { setModal } = props;
  const { options, getOptions, setOptions } = props;

  const { stages } = useLoadOptionsStages(projectID);

  const [newStages, setNewStages] = useState([]);

  useEffect(() => {
    if (stages && !isEmpty(stages.data.optionsAnalysisStages)) {
      setNewStages(stages.data.optionsAnalysisStages);
    }
  }, [stages]);

  const [error, setError] = useState({});

  const data = {
    newStages,
    projectID,
    project,
    setModal,
    setError,
    options,
    getOptions,
    setOptions,
  };

  return (
    <div className="edit-stages-container">
      <div className="flex-container-stages">
        <Headings newStages={newStages} />
        {newStages !== undefined && (
          <GenerateFields newStages={newStages} setNewStages={setNewStages} />
        )}
      </div>
      <div className="general-row-container" id="add-stages-button">
        <div className="general-upload-button" onClick={addStage}>
          {translate("Add Options Analysis")}
        </div>
      </div>
      <div className="general-row-container">
        <ErrorMessage error={error} setError={setError} />
      </div>

      <div className="general-button-container">
        <div
          id="save-stages-button"
          className="general-upload-button"
          onClick={async () => {
            submitStages(data);
          }}
        >
          {translate("Save")}
        </div>
      </div>
    </div>
  );

  function addStage() {
    let temp = newStages;
    if (temp.length < 6) {
      temp.push({
        project_id: projectID,
        number: "",
        name: "",
        code: "",
      });
      setNewStages(temp.map((x) => x));
    }
  }
}

function ErrorMessage(props) {
  const { error } = props;
  return (
    <div className="project-stages-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function Headings(props) {
  const { newStages } = props;

  if (isEmpty(newStages)) {
    return null;
  }

  return (
    <div className="stage-field-titles">
      <div className="input-stages-code">
        <b>{translate("Number")}</b>
      </div>
      <div className="input-stages">
        <b>{translate("Options Analysis Title")}</b>
      </div>
      <div className="input-stages-code">
        <b>{translate("Code")}</b>
      </div>
    </div>
  );
}
