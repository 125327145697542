import isEmpty from "../../validation/is-empty";

export async function projectAdaptor(response) {
  const project = await response.json();
  return projectMapping(project);
}

export async function projectsAdaptor(response) {
  const projects = await response.json();
  const adaptedProjects = [];

  // Only allow projects with an ID
  projects.forEach((project) => {
    if (!isEmpty(project.projectId)) {
      adaptedProjects.push(projectMapping(project));
    }
  });

  return adaptedProjects;
}

function projectMapping(project) {
  // Check for malformed units
  let unit_of_measure = "";
  if (project.unitOfMeasure === "m�") {
    unit_of_measure = "m²";
  } else {
    unit_of_measure = project.unitOfMeasure;
  }

  // Check for null currency symbols
  let currency_symbol = "";
  if (!isEmpty(project.currencySymbol)) {
    currency_symbol = project.currencySymbol;
  }

  return {
    // IDs
    id: project.projectId,
    company_id: project.companyId,

    // Project Details
    job_number: project.jobNumber,
    title: project.title,

    // Project Categories
    sector: project.sector,
    sub_sector: project.subSector,
    quality: project.quality,
    type: project.projectType,
    currency: project.currency,
    currency_symbol: currency_symbol,
    procurement: project.procurement,
    region: project.region,
    country: project.country,
    city: project.city,
    address: project.address,
    search_tags: project.searchTags,

    // Project Location
    lat: project.lat,
    lng: project.lng,
    post_code: project.postCode,
    tpi_city: project.tpiCity,
    project_phase: project.projectPhase,
    unit_of_measure: unit_of_measure,
    measurement_system: project.measurementSystem,

    // Other
    time_created: project.timeCreated,
    terminologySet: project.terminologySet,
    isDeleted: project.isDeleted,

    // Legacy
    image: project.image,
    visits: project.visits,
    accessKey: project.accessKey,
  };
}
