//Translation
import { tranStr } from "../../utils/translation";
import { ImageDisplay } from "../images/ImageDisplay";

export function columns() {
  return [
    {
      heading: tranStr(""),
      key: "image",
      type: "IMAGE",
      width: 42,
      customComponent: (value) => {
        return (
          <ImageDisplay
            image={value}
            imageWidth={"20px"}
            imageHeight={"20px"}
            borderRadius={"10px"}
            isBezierDisabled={true}
          />
        );
      },
    },
    {
      heading: tranStr("First Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("Surname"),
      key: "surname",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("Job Title"),
      key: "jobTitle",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Email"),
      key: "email",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Region"),
      key: "region",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("Country"),
      key: "country",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },

    {
      heading: tranStr("Company"),
      key: "company_name",
      type: "DESCRIPTION",
      width: 120,
      isFiltered: true,
    },
    {
      heading: tranStr("Profile"),
      key: "hasProfile",
      type: "DESCRIPTION",
      width: 60,
      isFiltered: true,
    },
    {
      heading: tranStr("Created"),
      key: "timeCreated",
      type: "DATE",
      width: 160,
    },
    {
      heading: tranStr("Visited"),
      key: "timeVisited",
      type: "DATE",
      width: 160,
    },
  ];
}
