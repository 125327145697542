import ProjectImage from "../admin-projects/ProjectImage";
import { CommentNotification as ProjectCommentNotification } from "../portfolio-projects/ProjectItem";

export default function ProjectsListItem(props) {
  const { project } = props;
  const { setSelectedProjectId } = props;
  const { isSelected } = props;

  return (
    <div
      key={`correspondence_${project.id}`}
      className={`correspondence-project ${isSelected ? "selected" : ""}`}
      onClick={() => {
        setSelectedProjectId(project.id);
      }}
    >
      <ProjectImage project={project} />
      <b className="correspondence-projects-list-item">{project.title}</b>
      <ProjectCommentNotification
        notification={project.notify_estimate_comment}
      />
    </div>
  );
}
