import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getComparisonComments } from "../../api/estimates/EstimateReconciliationApi";
import Modal from "../modal/Modal";

import "./ReconciliationCommentModal.css";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";

import { hasRoles } from "../../utils/roles";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";

function ReconciliationCommentModal(props) {
  const { title, row, comment } = props;
  const { setComparisonComments } = props;
  const { user } = props.auth;

  const [modal, setModal] = useState(false);

  const data = {
    row: row,
    title: title,
    comment: comment,
    setComparisonComments: setComparisonComments,
    setShow: setModal,
    user: user,
  };

  if (isEmpty(row.comparison_id) || isEmpty(row.id)) {
    return null;
  }

  let icon = (
    <div className="note-icon">
      <i className="far fa-comment" />
    </div>
  );

  if (!isEmpty(comment)) {
    if (!isEmpty(comment.comment_text)) {
      icon = (
        <div className="note-icon-highlight">
          <i className="fas fa-comment" />
        </div>
      );
    }
  }

  return (
    <div>
      <div
        className="note-open"
        onClick={() => {
          setModal(true);
        }}
      >
        <div className="note-icon">{icon}</div>
      </div>
      <Modal
        // Modal Props
        title={title}
        Component={ReconciliationNote}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        show={modal}
        setShow={setModal}
        width={"600px"}
        height={"500px"}
      />
    </div>
  );
}

function ReconciliationNote(props) {
  const { data } = props;
  const [commentText, setCommentText] = useState("");
  const [internalCommentText, setInternalCommentText] = useState("");

  useEffect(() => {
    if (!isEmpty(data.comment)) {
      setCommentText(data.comment.comment_text || "");
      setInternalCommentText(data.comment.internal_comment_text || "");
    }
  }, [data.comment]);

  return (
    <div className="reconciliation-comment">
      <div className="reconciliation-comment-container">
        <div className="reconciliation-comment-top-row">
          <div>
            <b>Note</b>
          </div>
        </div>
        <TextDisplayEdit
          user={data.user}
          text={commentText}
          setText={setCommentText}
        />
      </div>
      {hasRoles(data.user.roles, ["CostManager"]) && (
        <div className="reconciliation-internal-comment-container">
          <div className="reconciliation-comment-top-row">
            <div>
              <b>Internal Note</b> (Visible only to Cost Managers)
            </div>
          </div>
          <TextDisplayEdit
            user={data.user}
            text={internalCommentText}
            setText={setInternalCommentText}
          />
        </div>
      )}
      {hasRoles(data.user.roles, ["CostManager"]) && (
        <div className="general-button-container">
          <div
            className="general-upload-button"
            onClick={() => {
              onClickSave(
                // COST PLAN API
                cpAPIs().comparison_commentary_set,
                commentText,
                internalCommentText,
                data
              );
            }}
          >
            Save
          </div>
        </div>
      )}
    </div>
  );
}

function TextDisplayEdit(props) {
  const { text, setText } = props;
  const { user } = props;

  if (hasRoles(user.roles, ["CostManager"])) {
    return (
      <textarea
        className="reconciliation-comment-input"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    );
  } else {
    return <div className="reconciliation-comment-input">{text}</div>;
  }
}

async function onClickSave(url, commentText, internalCommentText, data) {
  const payload = JSON.stringify({
    row_id: data.row.id,
    comparison_row_id: data.row.comparison_id,
    project_id: data.row.project_id,
    estimate_id: data.row.estimate_id,
    comment_text: commentText,
    internal_comment_text: internalCommentText,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Comparison Save Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }

  data.setComparisonComments(
    await getComparisonComments(data.row.estimate_id, data.row.project_id)
  );
  data.setShow(false);
}

export default connect((state) => ({
  auth: state.auth,
}))(ReconciliationCommentModal);
