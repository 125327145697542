import { pcAPIs } from "../api-endpoints/PostContractEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export async function getProjectPostContract(projectIDs, abortSignal) {
  const url = pcAPIs().get_pc_configs;
  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectPostContract Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addProjectPostContractData(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectPostContract(projectIDs, abortSignal);

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.project_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}

export async function addProjectsPostContract(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectsPC(projectIDs, abortSignal);

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};
    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.ProjectId.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      project.postContractPortfolioTotals = newData.PortfolioTotals;
      const combinedProject = project;

      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });

  return updatedProjects;
}

export async function getProjectsPC(projectIDs, abortSignal) {
  const url = pc2APIs().get_projects_post_contract;
  const payload = JSON.stringify({
    projectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectsPC Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function getProjectCostReportStats(projectIDs, abortSignal) {
  const url = pc2APIs().get_cost_report_stats;
  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectCostReportStats Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addProjectCostReportStats(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectCostReportStats(
    projectIDs,
    abortSignal
  );

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.project_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}
