import React from "react";
import generateXLSX from "../../utils/spreadsheets";

export default function ExportTPI(props) {
  const { TPI } = props;
  const { selectedLocations } = props;

  if (selectedLocations.length < 1) {
    return null;
  }

  let headings = [];

  // Add selected locations as headings
  headings.push(""); // One empty cell in the top left
  selectedLocations.forEach((loc) => {
    headings.push(loc);
    headings.push("");
    headings.push(""); // 2 blank cells after each heading
  });

  // Format Rows
  let data = [];

  // Add data
  TPI.fullTable.forEach((rows) => {
    let array = [];
    rows.forEach((cell) => {
      // Only add data that match selected locations
      if (
        selectedLocations.some(
          (loc) => loc === cell.location || cell.location === "*"
        )
      ) {
        array.push(cell.value);
      }
    });
    data.push(array);
  });

  // Add subheadings
  let subHeadings = [];
  TPI.subHeadings.forEach((e) => {
    // Only add subheadings that match selected locations
    if (
      selectedLocations.some(
        (location) => location === e.location || e.location === "*"
      )
    ) {
      subHeadings.push(e.value);
    }
  });
  // Add headings to the top of rows
  data.unshift(subHeadings);
  data.unshift(headings);

  return (
    <div
      className="estimate-export-button"
      onClick={() => {
        generateXLSX("TPI Data", data);
      }}
    >
      <i className="fas fa-download"></i>
    </div>
  );
}
