import React, { useState } from "react";

// Redux
import { connect } from "react-redux";

// Components
import Spinner from "../common/Spinner";
import PortfolioEditSelectProjectsTable from "./PortfolioEditSelectProjectsTable";
import isEmpty from "../../validation/is-empty";

// API
import { usePortfolioProjectSelection } from "../../api/portfolio/PortfolioProjectsAPI";
import { savePortfolioProjects } from "../../api/portfolio/PortfolioAPI";
import { getPortfolioProjects } from "../../api/projects/ProjectAPI";

function PortfolioEditSelectProjects(props) {
  const { show, setShow } = props;
  const { selectedData } = props;
  const { portfolios } = props;
  const { selectedPortfolio } = props;
  const { setProjects } = props;

  // API
  const { projects, projectsLoading } = usePortfolioProjectSelection(show);

  const [selectedUsers, setSelectedUsers] = useState(selectedData);
  const [errors, setErrors] = useState({});

  if (isEmpty(projects) || projectsLoading) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }
  return (
    <div>
      <PortfolioEditSelectProjectsTable
        data={projects}
        dataLoading={projectsLoading}
        selectedData={selectedUsers}
        setShow={setShow}
        setSelectedUsers={setSelectedUsers}
      />
      <div className="general-row-container">
        <Errors errors={errors} setErrors={setErrors} />
      </div>

      <div className="general-row-container-double">
        <Save
          selectedUsers={selectedUsers}
          setShow={setShow}
          portfolios={portfolios}
          selectedPortfolio={selectedPortfolio}
          setProjects={setProjects}
          setErrors={setErrors}
        />
      </div>
    </div>
  );
}

function Errors(props) {
  const { errors } = props;
  return (
    <div className="company-create-error-container">
      <div className={errors.type}>
        <b>{errors.text}</b>
      </div>
    </div>
  );
}

function Save(props) {
  const { setShow } = props;
  const { selectedUsers } = props;
  const { portfolios } = props;
  const { selectedPortfolio } = props;
  const { setProjects } = props;
  const { setErrors } = props;

  return (
    <div className="admin-modal-cancel-button-container">
      <div
        id={"portfolio-edit-select-projects-save-button-id"}
        className="create-project-save-cost-managers-button"
        onClick={async () => {
          try {
            setErrors({
              text: "Saving...",
              type: "feedback-success",
            });
            await savePortfolioProjects(
              selectedPortfolio,
              selectedUsers.map((p) => p.id)
            );
            portfolios.set(await portfolios.get());
            setProjects(await getPortfolioProjects(selectedPortfolio.id));
            setErrors({
              text: "Saved Successfully",
              type: "feedback-success",
            });
            await AutoCloseModal(setShow);
          } catch (e) {
            setErrors({
              text: "Save Failed",
              type: "feedback-error",
            });
          }
        }}
      >
        Save
      </div>
    </div>
  );
}

// Use here until we can combine with Nagaraj's global solution
const MODAL_AUTO_CLOSE_TIMEOUT = 2000;

async function AutoCloseModal(setModal) {
  setTimeout(() => setModal(false), MODAL_AUTO_CLOSE_TIMEOUT);
}

// Bring in the auth state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PortfolioEditSelectProjects);
