import React, { useState, useEffect } from "react";

import isEmpty from "../../validation/is-empty";
import CheckboxesVertical from "../checkboxes/CheckboxesVertical";
import { translate } from "../../utils/translation";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { sendData } from "../../api/estimates/EstimateSubtotalAPI";
import { enabledSubTotals } from "./CostPlanningSubtotalDonutModal.functions";

import "./CostPlanningSubTotalDonutModal.css";
import { DisplayError } from "../project-control/ProjectControl";

export default function CostPlanningSubTotalDonutModal(props) {
  // Props
  const { projectId, estimate } = props;
  const { CPs, project, setShow } = props;

  // Feature Selection
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(estimate) && !isEmpty(estimate.rows)) {
      // Set toggle Features
      const selectedSubtotals = [];
      const allSubtotals = [];

      estimate.rows.forEach((row) => {
        if (row.code === "st") {
          // List of subtotals
          allSubtotals.push({
            label: row.description,
            value: row.id.toString(),
          });
          // List of selected subtotals. If it doesn't exist it defaults to true
          if (row.is_selected || isEmpty(row.is_selected)) {
            selectedSubtotals.push({ value: row.id.toString() });
          }
        }
      });

      setOptions(allSubtotals);
      setSelected(selectedSubtotals);
    }
  }, [estimate]);

  if (isEmpty(estimate)) {
    return null;
  }

  const data = {
    url: cpAPIs().save_selected_subtotals,
    CPs: CPs,
    project: project,
    setShow: setShow,
    setError: setError,
    projectData: {
      estimateID: estimate.id,
      projectId: projectId,
      subTotalSelections: enabledSubTotals(selected, options),
    },
  };

  // Return
  return (
    <div className="subtotal-control-features">
      <ProjectControlSettings
        label={"Select Subtotals"}
        selection={selected}
        setFunction={setSelected}
        options={options}
      />
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      {<SaveButton data={data} />}
    </div>
  );
}

function ProjectControlSettings(props) {
  const { selection, setFunction } = props;
  const { options } = props;

  return (
    <div className="subtotal-control-settings">
      <CheckboxesVertical
        array={options}
        setFunction={setFunction}
        selection={selection}
      />
    </div>
  );
}

function SaveButton(props) {
  const { data } = props;
  return (
    <div className="general-button-container">
      <div
        className="general-upload-button"
        onClick={() => {
          sendData(data);
        }}
      >
        {translate("Save")}
      </div>
    </div>
  );
}
