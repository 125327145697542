// React
import React, { useState } from "react";
import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";

import OptionsAnalysisStages from "../options-analysis/OptionsAnalysisStages";

// Functions
import { hasRoles } from "../../utils/roles";

export default function OptioneeringControlComponent(props) {
  const { user } = props;
  const { project, projectID } = props;
  const { options, getOptions, setOptions } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="project-control-panel-controls">
      <h1 className="display-4">Options Analysis Panel</h1>
      <Button
        Component={OptionsAnalysisStages}
        projectID={projectID}
        project={project}
        options={options}
        getOptions={getOptions}
        setOptions={setOptions}
        icon={<i className="fas fa-chart-bar" />}
        width={"670px"}
        text={translate("Configure Analyses")}
        enabled={true}
      />
    </div>
  );
}

function Button(props) {
  const [modal, setModal] = useState(false);
  const { icon, text, enabled, Component } = props;

  if (!enabled) {
    return (
      <div className="project-control-panel-button-container">
        <div className={"project-control-panel-button-disabled"}>{icon}</div>
        <h1
          className="display-4"
          style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
        >
          {text}
        </h1>
        <Modal
          // Modal Props
          title={text}
          Component={Component}
          // Component Props
          show={modal}
          setShow={setModal}
          modal={modal}
          setModal={setModal}
          {...props}
        />
        <span className="tooltiptext">{"Returning Soon"}</span>
      </div>
    );
  }

  return (
    <div className="project-control-panel-button-container">
      <div
        className={"project-control-panel-button"}
        id={"optioneering-btn"}
        onClick={() => {
          setModal(true);
        }}
      >
        {icon}
      </div>
      <h1
        className="display-4"
        style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
      >
        {text}
      </h1>
      <Modal
        // Modal Props
        title={text}
        Component={Component}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}
