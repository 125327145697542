import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

import "./ProjectControlEditHighlights.css";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { DisplayError } from "./ProjectControl";

import {
  saveFunction,
  updateField,
  deleteField,
} from "./ProjectControlEdit.functions";

export default function ProjectControlEditHighlights(props) {
  const { projectID } = props;
  const { project } = props;
  const { CP, CPs } = props;
  const { setModal } = props;

  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      highlight: "",
    },
  ]);

  useEffect(() => {
    if (!isEmpty(CP)) {
      if (!isEmpty(CP.highlights)) {
        setFields(CP.highlights);
      }
    }
  }, [CP]);

  // No Cost Plans
  if (isEmpty(CP)) {
    return (
      <div className="project-control-advice">
        {translate(
          "Project Highlights are available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  return (
    <div className="project-control-advice">
      <div className="advice-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {translate(
            "Add up to 5 Project Highlights below to be displayed on the Cost Planning Dashboard."
          )}
        </div>
      </div>
      <FieldHeadings />
      <DecisionFields fields={fields} setFields={setFields} />
      <div className="general-row-container">
        <AddField fields={fields} setFields={setFields} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <div
          id={"advice-highlight-save-button"}
          className="general-upload-button"
          onClick={async () => {
            fields.forEach((f) => {
              f.stage = CP.stage;
              f.revision = CP.version;
            });

            const payload = JSON.stringify({
              highlights: fields,
              projectID: projectID,
              stage: CP.stage,
              revision: CP.version,
              cost_plan_id: CP.cost_plan_id,
            });
            const data = {
              projectID: projectID,
              project: project,
              CPs: CPs,
              setModal: setModal,
              setError: setError,
              fields: fields,
              CP: CP,
              url: cpAPIs().set_highlights,
              payload: payload,
            };

            await saveFunction(data);
          }}
        >
          {translate("Save")}
        </div>
      </div>
    </div>
  );
}

function FieldHeadings() {
  return (
    <div className="advice-field-container">
      <div className="advice-highlight">
        <b>{translate("Project Highlight")}</b>
      </div>

      <div className="advice-delete-spacer"></div>
    </div>
  );
}

function AddField(props) {
  const { fields } = props;
  const { setFields } = props;

  return (
    <div
      id={"advice-highlight-add-button"}
      className="general-modal-button"
      onClick={() => {
        let temp = [...fields];

        if (temp.length >= 5) {
          return;
        }

        temp.push({
          highlight: "",
        });
        setFields(temp);
      }}
    >
      {translate("Add Field")}
    </div>
  );
}

function DecisionFields(props) {
  const { fields } = props;
  const { setFields } = props;

  return fields.map((f, i) => {
    return (
      <div key={i} className="advice-field-container">
        <input
          id={"advice-highlight-input-" + i}
          className="advice-highlight"
          value={f.highlight}
          maxLength={150}
          onChange={(e) => {
            updateField("highlight", e.target.value, i, fields, setFields);
          }}
        />

        <div
          id={"advice-highlight-delete-field-" + i}
          className="advice-delete-button"
          onClick={() => deleteField(i, fields, setFields)}
        >
          <i className="far fa-trash-alt" />
        </div>
      </div>
    );
  });
}
