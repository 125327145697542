export async function userAdaptor(response) {
  const user = await response.json();
  return userMapping(user);
}

export async function usersAdaptor(response) {
  const users = await response.json();
  return users.map((user) => {
    return userMapping(user);
  });
}

function userMapping(user) {
  return {
    // IDs
    id: user.id, // activeDirectoryId

    // User Details
    name: user.name,
    surname: user.surname,
    jobTitle: user.jobTitle,

    // User Location
    city: user.city,
    country: user.country,
    region: user.region,

    // User Contact
    email: user.email,
    phoneNumber: user.phoneNumber,
    mobileNumber: user.mobileNumber,
    linkedin: user.linkedin,

    // Metadata
    hasPrivacyConsent: user.hasPrivacyConsent,
    accessKey: user.accessKey,
    timeCreated: user.timeCreated,
    timeVisited: user.timeVisited,
    hasProfile: user.hasProfile,
    isDeleted: false,
  };
}
