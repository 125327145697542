import React from "react";
import { translate } from "../../utils/translation";
import "./CashflowToggle.css";
import { updateCostPlanCashflow } from "../../api/cost-planning/CashflowAPI";
import isEmpty from "../../validation/is-empty";

export default function CashflowToggle(props) {
  const { cashflowBasis, setCashflowBasis } = props;
  const { CP } = props;

  if (isEmpty(CP)) {
    return null;
  }

  const { cashflow } = CP;

  const projectID = cashflow?.ProjectId;
  const costPlanId = cashflow?.CostPlanId;

  let graphToggle;
  let tableToggle;
  if (cashflowBasis === "split") {
    graphToggle = "cashflow-toggle-button-in-left";
    tableToggle = "cashflow-toggle-button-out-right";
  } else {
    graphToggle = "cashflow-toggle-button-out-left";
    tableToggle = "cashflow-toggle-button-in-right";
  }

  return (
    <div className="cashflow-toggle">
      <div
        id={"cashflow-toggle-button-split"}
        className={graphToggle}
        onClick={async () => {
          updateCostPlanCashflow(projectID, costPlanId, "split");
          setCashflowBasis("split");
        }}
      >
        {translate("Separate")}
      </div>
      <div
        id={"cashflow-toggle-button-join"}
        className={tableToggle}
        onClick={async () => {
          updateCostPlanCashflow(projectID, costPlanId, "join");
          setCashflowBasis("join");
        }}
      >
        {translate("Combine")}
      </div>
    </div>
  );
}
