import React from "react";

import "./EstimateCommentaryToggle.css";

import { translate } from "../../utils/translation";

export default function EstimateCommentaryToggle(props) {
  const { ectBasis, setBasis } = props;

  let graphToggle;
  let tableToggle;
  if (ectBasis === "TEXT") {
    graphToggle = "ect-toggle-button-in-left";
    tableToggle = "ect-toggle-button-out-right";
  } else {
    graphToggle = "ect-toggle-button-out-left";
    tableToggle = "ect-toggle-button-in-right";
  }

  return (
    <div className="ect-toggle">
      <div
        className={graphToggle}
        onClick={async () => {
          setBasis("TEXT");
        }}
      >
        {translate("Text")}
      </div>
      <div
        className={tableToggle}
        onClick={async () => {
          setBasis("TABLE");
        }}
      >
        {translate("Table")}
      </div>
    </div>
  );
}
