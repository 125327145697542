import { detectDeployment } from "./Utilities";

export function projectAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev.cost-clarity.com/apis/projectservice/v1.0",
    deployedService: "/apis/projectservice/v1.0",
  };
  const D = detectDeployment(config);

  // Deployed local  "https://localhost:44382/v1.0"
  // Deployed dev "https://dev.cost-clarity.com/apis/projectservice/v1.0"
  // Deployed test "https://test.cost-clarity.com/apis/projectservice/v1.0"
  // Deployed prd "https://www.cost-clarity.com/apis/projectservice/v1.0"

  return {
    // Does not use a paramater
    get_all_projects: `${D}/Project/Get`, // OK

    // Uses a Paramater
    get_project: `${D}/Project/Get`, // OK

    // Get Portfolio
    GetProjectDetailByUser: `${D}/Project/GetProjectDetailByUser`, // OK

    // Retrieves the Project information based on CompanyIDs provided
    get_projects_by_company_id: `${D}/Project/GetProjectListByCompanyId`, // OK

    // Retrieves the Project information based on ProjectIds provided
    get_projects_by_project_ids: `${D}/Project/GetProjectList`, // OK

    // Creates the Project entry  In Database
    create_project: `${D}/Project/Post`, // OK

    // Update Project
    update_project: `${D}/Project/Put`, // OK

    // Delete
    delete_project: `${D}/Project/Delete`, // OK

    // Edit Project
    upload_project_image: `${D}/Project/UploadImage`, // OK
  };
}
