import React from "react";
//Translations
import { tranStr } from "../../utils/translation";

import { ImageDisplay } from "../images/ImageDisplay";

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr(""),
      key: "image",
      type: "IMAGE",
      width: 42,
      customComponent: (value) => {
        return (
          <ImageDisplay
            image={value}
            imageWidth={"20px"}
            imageHeight={"20px"}
            borderRadius={"10px"}
            isBezierDisabled={true}
          />
        );
      },
    },
    {
      heading: tranStr("First Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Surname"),
      key: "surname",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Job Title"),
      key: "jobTitle",
      type: "DESCRIPTION",
      width: 220,
      isFiltered: true,
    },
    {
      heading: tranStr("Email"),
      key: "email",
      type: "DESCRIPTION",
      width: 260,
      isFiltered: true,
    },
  ];
}
