import React, { useState } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { AutoCloseModal } from "../modal/Modal";

import "./BenchmarkCreateLoadOrSaveModal.css";

import Spinner from "../common/Spinner";

import BenchmarkCreateAnalysisPersistence from "./BenchmarkCreateAnalysisPersistence.class";

import {
  saveBenchmarkAnalysis,
  deleteBenchmarkAnalysis,
} from "./BenchmarkCreate.functions";
import { FormHeader, FormBodyReadonly } from "./BenchmarkCreate.components";

import { SAVE_MESSAGES } from "../estimates/EstimatesTPISaveMessages";
import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

import {
  getSelectedItem,
  useSectionedItems,
  prepareForms,
} from "./BenchmarkCreateLoadOrSaveModal.functions";

const { FLAGS } = MASTER_BENCHMARKING;

/// props validation | SQ(javascript:S6774)
BenchmarkCreateLoadOrSaveModal.propTypes = {
  user: PropTypes.object,
  currentSelection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(BenchmarkCreateAnalysisPersistence),
  ]),
};
///
export default function BenchmarkCreateLoadOrSaveModal(props) {
  const { user } = props;
  const { currentSelection } = props;

  const [showDelete, setShowDelete] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState(null);

  const { itemsLoading, items, setItems } = useSectionedItems(
    user,
    currentSelection
  );

  return (
    <TwoPane
      {...props}
      user={user}
      itemsLoading={itemsLoading}
      items={items}
      setItems={setItems}
      selectedItemId={selectedItemId}
      setSelectedItemId={setSelectedItemId}
      showDelete={showDelete}
      setShowDelete={setShowDelete}
      showSave={showSave}
      setShowSave={setShowSave}
      showLoad={showLoad}
      setShowLoad={setShowLoad}
    />
  );
}

/// props validation | SQ(javascript:S6774)
TwoPane.propTypes = {};
///
function TwoPane(props) {
  return (
    <div className="benchmark-create-load-or-save-modal-wrapper">
      <LeftPane {...props} />
      <RightPane {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPane.propTypes = {
  itemsLoading: PropTypes.bool,
};
///
function LeftPane(props) {
  const { itemsLoading } = props;

  if (itemsLoading) {
    return (
      <div className="benchmark-create-load-or-save-modal-left-pane loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="benchmark-create-load-or-save-modal-left-pane">
      <LeftPaneList {...props} />
      <LeftPaneFooter {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPaneList.propTypes = {
  items: PropTypes.array,
  setShowDelete: PropTypes.func,
  setShowSave: PropTypes.func,
  setShowLoad: PropTypes.func,
};
///
function LeftPaneList(props) {
  const { items } = props;
  const { setShowDelete, setShowSave, setShowLoad } = props;

  const currentSelectionSectionItems = items.filter(
    (i) => i.section === MASTER_BENCHMARKING.SECTIONS.CURRENT_SELECTION.TYPE
  );
  const previouslySavedSectionItems = items.filter(
    (i) => i.section === MASTER_BENCHMARKING.SECTIONS.PREVIOUSLY_SAVED.TYPE
  );

  return (
    <div className="benchmark-create-load-or-save-modal-left-pane-list">
      <LeftPaneListSection
        {...props}
        items={currentSelectionSectionItems}
        heading={MASTER_BENCHMARKING.SECTIONS.CURRENT_SELECTION.LABEL}
        type={MASTER_BENCHMARKING.SECTIONS.CURRENT_SELECTION.TYPE}
        onClick={() => {
          setShowDelete(false);
          setShowSave(true);
          setShowLoad(false);
        }}
      />
      <LeftPaneListSection
        {...props}
        items={previouslySavedSectionItems}
        heading={MASTER_BENCHMARKING.SECTIONS.PREVIOUSLY_SAVED.LABEL}
        type={MASTER_BENCHMARKING.SECTIONS.PREVIOUSLY_SAVED.TYPE}
        onClick={() => {
          setShowDelete(true);
          setShowSave(false);
          setShowLoad(true);
        }}
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPaneListSection.propTypes = {
  items: PropTypes.array,
  heading: PropTypes.string,
  type: PropTypes.string,
};
///
function LeftPaneListSection(props) {
  const { items } = props;
  const { heading, type } = props;

  if (items.length === 0) {
    return <></>;
  }

  const validItems = items.filter((item) => !item[FLAGS.DELETED]);

  return (
    <div
      className={`benchmark-create-load-or-save-modal-left-pane-list-section ${type}`}
    >
      <span className="list-section-heading">
        {heading} ({validItems.length})
      </span>
      <div className="list-section-items">
        <LeftPaneListItems {...props} />
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPaneListItems.propTypes = {
  items: PropTypes.array,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedItemId: PropTypes.func,
  onClick: PropTypes.func,
};
///
function LeftPaneListItems(props) {
  const { items } = props;
  const { selectedItemId, setSelectedItemId } = props;
  const { onClick } = props;

  const validItems = items.filter((item) => !item[FLAGS.DELETED]);

  return (
    <>
      {validItems.map((item) => {
        const { id, title } = item;

        const key = `benchmark-create-load-or-save-modal-left-pane-list_${id}`;

        const isSelected = id === selectedItemId;
        const isSavePending = !!item[FLAGS.SAVE_PENDING];
        const isMarkedForDeletion = !!item[FLAGS.MARKED_FOR_DELETION];

        let className = "";
        isSelected && (className += " selected");
        isSavePending && (className += ` ${FLAGS.SAVE_PENDING}`);
        isMarkedForDeletion && (className += ` ${FLAGS.MARKED_FOR_DELETION}`);

        return (
          <LeftPaneListItem
            key={key}
            className={className}
            id={id}
            title={title}
            setSelectedItemId={setSelectedItemId}
            onClick={onClick}
          />
        );
      })}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPaneListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  setSelectedItemId: PropTypes.func,
  onClick: PropTypes.func,
};
///
function LeftPaneListItem(props) {
  const { className } = props;
  const { id, title } = props;
  const { setSelectedItemId } = props;
  const { onClick } = props;

  return (
    <button
      className={`benchmark-create-load-or-save-modal-list-item ${className}`}
      onClick={() => {
        setSelectedItemId(id);
        onClick?.();
      }}
    >
      <b className="list-item-title">{title}</b>
    </button>
  );
}

/// props validation | SQ(javascript:S6774)
LeftPaneFooter.propTypes = {};
///
function LeftPaneFooter(props) {
  return (
    <div className="benchmark-create-load-or-save-modal-footer left-pane"></div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPane.propTypes = {};
///
function RightPane(props) {
  return (
    <div className="benchmark-create-load-or-save-modal-right-pane">
      <RightPaneContents {...props} />
      <RightPaneFooter {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneContents.propTypes = {
  items: PropTypes.array,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
///
function RightPaneContents(props) {
  const { items } = props;
  const { selectedItemId } = props;

  if (isEmpty(items) || selectedItemId === null) {
    return (
      <RightPaneInformationContent
        {...props}
        icon={MASTER_BENCHMARKING.ICONS.APPLY}
        text={MASTER_BENCHMARKING.WARNINGS.ITEM_NOT_SELECTED_FOR_LOAD_OR_SAVE}
      />
    );
  }

  const { item, index } = getSelectedItem({ items, selectedItemId });
  const isMarkedForDeletion = !!item[FLAGS.MARKED_FOR_DELETION];

  return (
    <div className="benchmark-create-load-or-save-modal-list-item-contents">
      <RightPaneContentContainer {...props} index={index} item={item} />
      <RightPaneInformationContent
        {...props}
        show={isMarkedForDeletion}
        className="display-overlay"
        icon={MASTER_BENCHMARKING.ACTIONS.DELETE.ICON}
        text={MASTER_BENCHMARKING.WARNINGS.ITEM_DELETE_CONFIRMATION}
        ActionComponent={DeleteConfirmation}
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneInformationContent.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  ActionComponent: PropTypes.func,
};
///
function RightPaneInformationContent(props) {
  const { show = true } = props;
  const { className = "" } = props;
  const { ActionComponent } = props;

  if (!show) {
    return <></>;
  }

  return (
    <div className={`list-item-information ${className}`}>
      <Information {...props} />
      {ActionComponent && <ActionComponent {...props} />}
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Information.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.any,
};
///
function Information(props) {
  const { icon, text } = props;

  return (
    <>
      <i className={`${icon} fa-10x`} />
      <div className="text">{text}</div>
    </>
  );
}

/// props validation | SQ(javascript:S6774)
DeleteConfirmation.propTypes = {};
///
function DeleteConfirmation(props) {
  return (
    <div className="actions-panel">
      <PermanentlyDeleteItemButton {...props} />
      <CancelChangesButton {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneContentContainer.propTypes = {
  item: PropTypes.object,
};
///
function RightPaneContentContainer(props) {
  const { item } = props; // item here is of type BenchmarkCreateAnalysisPersistence

  const { forms } = prepareForms(item);

  return (
    <div className="benchmark-create-load-or-save-modal-right-pane-content-container">
      <div className="benchmark-create-load-or-save-modal-right-pane-content-forms">
        <RightPaneContentTitle {...props} />
        {forms.map((form) => (
          <RightPaneContentForm key={form.key} {...form} />
        ))}
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneContentTitle.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.object,
};
///
function RightPaneContentTitle(props) {
  const { items, setItems } = props;
  const { index, item } = props;

  const titlePlaceholder = MASTER_BENCHMARKING.TITLE_PLACEHOLDER;

  function renameItem(name) {
    const _items = [...items];
    const _item = _items[index];
    _item[FLAGS.MARKED_FOR_DELETION] = false;
    _item[FLAGS.SAVE_PENDING] = true;

    _item.title = name || titlePlaceholder;

    setItems(_items);
  }

  const isEditable =
    item.section === MASTER_BENCHMARKING.SECTIONS.CURRENT_SELECTION.TYPE;

  const className = isEditable ? "editable" : "readonly";
  const title = item.title === titlePlaceholder ? "" : item.title;

  return (
    <div
      className={`benchmark-create-load-or-save-modal-right-pane-modal-title ${className}`}
    >
      <input
        className="title"
        placeholder={MASTER_BENCHMARKING.TITLE_PLACEHOLDER_HINT}
        readOnly={!isEditable}
        value={title}
        onChange={(e) => renameItem(e.target.value)}
      />
      {isEditable && (
        <i className={MASTER_BENCHMARKING.ACTIONS.RENAME.ICON_LARGE}></i>
      )}
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneContentForm.propTypes = {
  className: PropTypes.string,
};
///
function RightPaneContentForm(props) {
  const { className = "" } = props;

  return (
    <div
      className={`benchmark-create-load-or-save-modal-right-pane-content-form ${className}`}
    >
      <FormHeader {...props} />
      <FormBodyReadonly
        {...props}
        className="benchmark-create-load-or-save-modal-right-pane-content-form-control"
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
RightPaneFooter.propTypes = {};
///
function RightPaneFooter(props) {
  const [message, setMessage] = useState("");

  return (
    <div className="benchmark-create-load-or-save-modal-footer right-pane">
      <DeleteItemButton {...props} />
      <div className="message">{message}</div>
      <SaveChangesButton {...props} setMessage={setMessage} />
      <LoadItemButton {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ActionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  isAvailable: PropTypes.bool,
  onClick: PropTypes.func,
};
///
function ActionButton(props) {
  const {
    id,
    className,
    tooltip,
    icon,
    label,
    isAvailable = true,
    onClick,
  } = props;

  return (
    <button
      id={id}
      className={`button ${className} ${isAvailable ? "" : "unavailable"}`}
      title={tooltip}
      onClick={onClick}
    >
      {icon && <i className={icon} />}
      {label && <span className="text">{label}</span>}
    </button>
  );
}

/// props validation | SQ(javascript:S6774)
DeleteItemButton.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDelete: PropTypes.bool,
  setShowDelete: PropTypes.func,
  setShowSave: PropTypes.func,
  setShowLoad: PropTypes.func,
};
///
function DeleteItemButton(props) {
  const { items, setItems } = props;
  const { selectedItemId } = props;
  const { showDelete } = props;
  const { setShowDelete, setShowSave, setShowLoad } = props;

  function deleteItem() {
    const _items = [...items];
    const index = _items.findIndex((row) => row.id === selectedItemId);

    const _item = _items[index];
    _item[FLAGS.MARKED_FOR_DELETION] = true;
    _item[FLAGS.SAVE_PENDING] = false;

    setItems(_items);

    setShowDelete(false);
    setShowSave(false);
    setShowLoad(false);
  }

  if (!showDelete) {
    return <></>;
  }

  return (
    <ActionButton
      id={"benchmark-create-load-or-save-modal-delete-item"}
      className={"advice-delete-button"}
      tooltip={MASTER_BENCHMARKING.ACTIONS.DELETE.TOOLTIP}
      icon={MASTER_BENCHMARKING.ACTIONS.DELETE.ICON}
      label={MASTER_BENCHMARKING.ACTIONS.DELETE.LABEL}
      onClick={deleteItem}
    />
  );
}

/// props validation | SQ(javascript:S6774)
PermanentlyDeleteItemButton.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedItemId: PropTypes.func,
};
///
function PermanentlyDeleteItemButton(props) {
  const { items, setItems } = props;
  const { selectedItemId, setSelectedItemId } = props;

  async function permanentlyDeleteItem() {
    const _items = [...items];
    const index = _items.findIndex((row) => row.id === selectedItemId);

    const _item = _items[index];
    _item[FLAGS.DELETED] = true;
    _item[FLAGS.SAVE_PENDING] = false;

    setItems(_items);
    setSelectedItemId(null);

    await deleteBenchmarkAnalysis({ item: _item });
  }

  return (
    <ActionButton
      id={"benchmark-create-load-or-save-modal-permanently-delete-item"}
      className={"general-delete-button"}
      tooltip={MASTER_BENCHMARKING.ACTIONS.DELETE_PERMANENT.TOOLTIP}
      icon={MASTER_BENCHMARKING.ACTIONS.DELETE_PERMANENT.ICON}
      label={MASTER_BENCHMARKING.ACTIONS.DELETE_PERMANENT.LABEL}
      onClick={permanentlyDeleteItem}
    />
  );
}

/// props validation | SQ(javascript:S6774)
CancelChangesButton.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setShowDelete: PropTypes.func,
  setShowSave: PropTypes.func,
  setShowLoad: PropTypes.func,
};
///
function CancelChangesButton(props) {
  const { items, setItems } = props;
  const { selectedItemId } = props;
  const { setShowDelete, setShowSave, setShowLoad } = props;

  function cancelItem() {
    const _items = [...items];
    const index = _items.findIndex((row) => row.id === selectedItemId);

    const _item = _items[index];
    _item[FLAGS.MARKED_FOR_DELETION] = false;
    _item[FLAGS.SAVE_PENDING] = false;

    setItems(_items);

    setShowDelete(true);
    setShowSave(false);
    setShowLoad(true);
  }

  return (
    <ActionButton
      id={"benchmark-create-load-or-save-modal-cancel-changes"}
      className={"general-modal-button"}
      tooltip={MASTER_BENCHMARKING.ACTIONS.CANCEL.TOOLTIP}
      icon={MASTER_BENCHMARKING.ACTIONS.CANCEL.ICON}
      label={MASTER_BENCHMARKING.ACTIONS.CANCEL.LABEL}
      onClick={cancelItem}
    />
  );
}

/// props validation | SQ(javascript:S6774)
SaveChangesButton.propTypes = {
  items: PropTypes.array,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setMessage: PropTypes.func,
  setModal: PropTypes.func,
  showSave: PropTypes.bool,
};
///
function SaveChangesButton(props) {
  const { items } = props;
  const { selectedItemId } = props;

  const { setMessage } = props;
  const { setModal } = props;

  const { showSave } = props;

  if (!showSave) {
    return <></>;
  }

  const { item } = getSelectedItem({ items, selectedItemId });

  const SAVE_MESSAGE_TIMEOUT = 2000; // 2 seconds

  async function saveItems() {
    setMessage(SAVE_MESSAGES.SAVING);

    const { response, errorMessage } = await saveBenchmarkAnalysis({ item });

    if (errorMessage.text) {
      setMessage(SAVE_MESSAGES.ERROR_MESSAGE(errorMessage.text));
    } else if (response.ok) {
      setMessage(SAVE_MESSAGES.SAVED);

      AutoCloseModal(setModal);
    } else {
      setMessage(SAVE_MESSAGES.SAVE_FAILED);
    }

    setTimeout(() => {
      setMessage(SAVE_MESSAGES.NONE);
    }, SAVE_MESSAGE_TIMEOUT);
  }

  return (
    <ActionButton
      id={"benchmark-create-load-or-save-modal-save-changes"}
      className={"general-upload-button"}
      tooltip={MASTER_BENCHMARKING.ACTIONS.SAVE.TOOLTIP}
      icon={MASTER_BENCHMARKING.ACTIONS.SAVE.ICON}
      label={MASTER_BENCHMARKING.ACTIONS.SAVE.LABEL}
      isAvailable={item.isGenerateReady}
      onClick={saveItems}
    />
  );
}

/// props validation | SQ(javascript:S6774)
LoadItemButton.propTypes = {
  items: PropTypes.array,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadAnalysisItem: PropTypes.func,
  setModal: PropTypes.func,
  showLoad: PropTypes.bool,
};
///
function LoadItemButton(props) {
  const { items } = props;
  const { selectedItemId } = props;
  const { loadAnalysisItem } = props;

  const { setModal } = props;

  const { showLoad } = props;

  if (!showLoad) {
    return <></>;
  }

  const { item } = getSelectedItem({ items, selectedItemId });

  async function loadItem() {
    setModal(false);
    await loadAnalysisItem({ item });
  }

  return (
    <ActionButton
      id={"benchmark-create-load-or-save-modal-load-item"}
      className={"general-modal-button"}
      tooltip={MASTER_BENCHMARKING.ACTIONS.LOAD.TOOLTIP}
      icon={MASTER_BENCHMARKING.ACTIONS.LOAD.ICON}
      label={MASTER_BENCHMARKING.ACTIONS.LOAD.LABEL}
      onClick={loadItem}
    />
  );
}
